import DnDList from 'components/DnDList';
import ModalSelectStore from 'components/ModalSelectStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useRef, useState, CSSProperties, useMemo, useCallback } from 'react';
import { setMinutes, setHours, isToday } from 'date-fns';
import { useStores } from 'stores';
import NumberIcon from './numberIcon';
import { ReactComponent as DeleteSvgIcon } from './assets/svg/delete.svg';
import { ReactComponent as MoveSvgIcon } from './assets/svg/move.svg';
import CostCenters from './CostCenters';
import { isEmpty, omit } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddressAutoComplete from 'components/AddressAutoComplete';
import { CurrentField } from 'stores/OrdersStore';
import { NumberOrString, Option, Place, ProviderInfo, Ride, Role, TariffClass } from '../../types/types';
import FavouritesModal from './FavouritesModal';
import DeliveryFields from './DeliveryFields';
import { isValidPhone } from 'utils/lib/isValidPhone';
import { getRandomId } from 'utils/lib/getRandomId';
import { handleEmployeeName } from 'utils/lib/regExp/handleEmployeeName';
import { formatPrice } from 'utils/lib/formatPrice';
import { RequirementsBlock } from './RequirementsBlock';
import PaymentMethodDialog from './paymentMethodDialog';
import ActivateDialog from '../Promocodes/activateDialog';
import { Tariff } from './Tariff';
import ModalBase from '../../components/ModalBase';
import PhoneInput from '../../components/Inputs/PhoneInput';
import { getCountryCca2 } from '../../utils/lib/formatPhone';
import { formatDateValue } from '../../utils/lib/formatDateValue';
import { DateTimeField } from '../../components/Inputs/DateTimeField';

interface Group {
  id?: NumberOrString;
  name: string;
  prices: number[];
  eta_s?: number;
  priority: number;
}

interface UniqueProvidersGroups {
  [key: number]: Group;
}

const Form = observer(({}: any): JSX.Element => {
  const { ordersStore, rolesStore, usersStore, managersStore, promocodesStore, rolesCostCentersStore } = useStores();

  const [isGuest, setIsGuest] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<any>();
  const [isInputActive, setIsInputActive] = useState<boolean>(false);
  const [windowSizes, setWindowSizes] = useState({ width: window?.innerWidth, height: window.innerHeight });
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const bottomSheetContentRef = useRef<HTMLDivElement>(null);
  const mapHandlerRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const toucheStartYRef = useRef<number>(0);
  const touchHandleDiff = useRef<number>(0);
  const lastPositionRef = useRef<number>(0);
  const [isBottomSheetDisabled, setIsBottomSheetDisabled] = useState<boolean>(false);
  const [hiddenBotoomSheet, setHiddenBotoomSheet] = useState<boolean>(false);
  const [visibleFavourites, setVisibleFavourites] = useState<boolean>(false);
  const currentDirectionRef = useRef<Omit<CurrentField, 'unset'>>();
  const bottomSnap = 250;
  const topSnap = 380;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [isOpenPaymentMethodDialog, setOpenPaymentMethodDialog] = useState<boolean>(false);
  const [activatePromocodeDialog, setActivatePromocodeDialog] = useState<boolean>(false);
  const { t } = useTranslation(['new_order', 'validation', 'actions']);
  const roleName = usersStore?.me?.role?.name;
  const [visibleEntranceModal, setVisibleEntranceModal] = useState(false);
  const [entrance, setEntrance] = useState('');
  const [today, setToday] = useState<Date>(new Date());
  const favouritesItem: Option[] | undefined =
    roleName === 'employee' || roleName === 'customer' || roleName === 'coordinator' || roleName === 'operator'
      ? [
          {
            id: 'favourites',
            label: t('favourites'),
            value: t('favourites'),
          },
        ]
      : undefined;
  /**id - passed when user tries to edit record */
  const { id } = useParams<any>() || {};
  const history = useHistory();
  const [errors, setErrors] = useState<any>({});
  const [employeeFilter, setEmployeeFilter] = useState<any>({});
  const [customerFilter, setCustomerFilter] = useState<any>({});
  const { newOrderInfo } = ordersStore || {};

  const destinations = useMemo(() => {
    const { interim_destinations = [], destination } = newOrderInfo;
    return [...interim_destinations, ...(destination ? [destination] : [])].map((dest) => ({
      value: dest,
      id: getRandomId(),
    }));
  }, [newOrderInfo.interim_destinations, newOrderInfo.destination]);

  const { isVip } = ordersStore;

  const applyFullHeight = (): void => {
    if (bottomSheetContentRef.current) {
      bottomSheetContentRef.current.classList.remove('swipe');
      bottomSheetContentRef.current.style.top = '52px';
      lastPositionRef.current = 52;
    }
    if (contentWrapperRef.current) contentWrapperRef.current.style.overflow = 'scroll';
  };

  useEffect(() => {
    const onMove = (e: any): void => {
      if (e.touches?.length > 1) e.preventDefault();
    };
    if (window.innerWidth > 991) {
      ordersStore?.updateCurrentField('source');
    }
    document.addEventListener('touchmove', onMove, { passive: false });
    return () => {
      document.removeEventListener('touchmove', onMove);
      ordersStore?.updateCurrentField('unset');
    };
  }, []);

  useEffect(() => {
    if (!ordersStore?.newOrderInfo?.employee_id) {
      setUserRole(null);
      setIsGuest(false);
    }
  }, [ordersStore?.newOrderInfo]);

  const onResize = () => {
    if (window) {
      setWindowSizes((state) => ({ ...state, width: window.innerWidth }));
    }
  };
  const onTouchStart = (e: any): void => {
    if (e.touches?.length > 1) return;
    toucheStartYRef.current = e.changedTouches[0].pageY;
    touchHandleDiff.current =
      toucheStartYRef.current - (bottomSheetContentRef.current?.getBoundingClientRect()?.top || 0);
  };

  const onTouchMove = (e: any): void => {
    if (!bottomSheetContentRef.current || isBottomSheetDisabled || e.touches?.length > 1) return;
    const diff = e.changedTouches[0].pageY - toucheStartYRef.current;
    bottomSheetContentRef.current.style.top = `${lastPositionRef.current}px`;
    bottomSheetContentRef.current.classList.remove('swipe');
    if (Math.abs(diff) < 5) return;
    if (diff > 0) {
      if (
        (wrapperRef.current?.getBoundingClientRect()?.top || 0) - 20 <=
        (bottomSheetContentRef.current?.getBoundingClientRect()?.top || 0)
      )
        return;
      if (isInputActive) {
        (document.activeElement as HTMLElement).blur();
        setIsInputActive(false);
      }
      bottomSheetContentRef.current.style.top = `${window.innerHeight - e.changedTouches[0].pageY + touchHandleDiff.current > bottomSnap ? e.changedTouches[0].pageY - touchHandleDiff.current : window.innerHeight - bottomSnap < 0 ? 52 : window.innerHeight - bottomSnap}px`;
      lastPositionRef.current =
        window.innerHeight - e.changedTouches[0].pageY + touchHandleDiff.current > bottomSnap
          ? e.changedTouches[0].pageY - touchHandleDiff.current
          : window.innerHeight - bottomSnap;
      if (lastPositionRef.current === window.innerHeight - bottomSnap) {
        if (contentWrapperRef.current && !isInputActive) contentWrapperRef.current.style.overflow = 'hidden';
        bottomSheetContentRef.current.scrollTo(0, 0);
      }
    } else {
      if (e.changedTouches[0].pageY - touchHandleDiff.current <= 52) return;
      bottomSheetContentRef.current.style.top = `${(window.innerHeight - topSnap >= 52 ? window.innerHeight - topSnap : 52) < bottomSheetContentRef.current?.getBoundingClientRect()?.top ? e.changedTouches[0].pageY - touchHandleDiff.current : window.innerHeight - topSnap >= 52 ? window.innerHeight - topSnap : 52}px`;
      lastPositionRef.current =
        (window.innerHeight - topSnap >= 52 ? window.innerHeight - topSnap : 52) <
        bottomSheetContentRef.current?.getBoundingClientRect()?.top
          ? e.changedTouches[0].pageY - touchHandleDiff.current
          : window.innerHeight - topSnap >= 52
            ? window.innerHeight - topSnap
            : 52;
    }
  };

  const onTouchEnd = (e: any): void => {
    if (!bottomSheetContentRef.current || isBottomSheetDisabled || e.touches?.length > 1) return;
    if (
      (wrapperRef.current?.getBoundingClientRect()?.top || 0) - 20 <=
      (bottomSheetContentRef.current?.getBoundingClientRect()?.top || 0)
    )
      return;
    const diff = e.changedTouches[0].pageY - toucheStartYRef.current;
    if (Math.abs(diff) < 5) return;
    let topEnd = bottomSnap;
    if (diff > 0) {
      if (Math.abs(diff) < 60) topEnd = topSnap;
      if (window.innerHeight - e.changedTouches[0].pageY + touchHandleDiff.current < bottomSnap) return;
      document.documentElement.style.setProperty(
        '--top-start',
        `${bottomSheetContentRef.current?.getBoundingClientRect()?.top}px`,
      );
      document.documentElement.style.setProperty('--top-end', `${window.innerHeight - topEnd}px`);
      bottomSheetContentRef.current.classList.add('swipe');
      if (contentWrapperRef.current)
        contentWrapperRef.current.style.overflow = topEnd === topSnap ? 'scroll' : 'hidden';
      bottomSheetContentRef.current.scrollTo(0, 0);
      lastPositionRef.current =
        window.innerHeight - e.changedTouches[0].pageY > bottomSnap
          ? e.changedTouches[0].pageY
          : window.innerHeight - topEnd;
    } else {
      if (Math.abs(diff) > 60) topEnd = topSnap;
      if (e.changedTouches[0].pageY - touchHandleDiff.current <= 52) return;
      document.documentElement.style.setProperty(
        '--top-start',
        `${bottomSheetContentRef.current?.getBoundingClientRect()?.top}px`,
      );
      document.documentElement.style.setProperty(
        '--top-end',
        `${window.innerHeight - topSnap >= 52 ? window.innerHeight - topEnd : 52}px`,
      );
      bottomSheetContentRef.current.classList.add('swipe');
      if (contentWrapperRef.current)
        contentWrapperRef.current.style.overflow = topEnd === topSnap ? 'scroll' : 'hidden';

      lastPositionRef.current = window.innerHeight - topSnap >= 52 ? window.innerHeight - topEnd : 52;
    }
  };

  const onMap = (field: Omit<CurrentField, 'unset'>, e: any): void => {
    e.stopPropagation();
    setHiddenBotoomSheet(true);
    ordersStore.updateCurrentField(field as CurrentField);
  };

  useEffect(() => {
    document.addEventListener('resize', onResize);
    bottomSheetContentRef.current?.addEventListener('touchstart', onTouchStart);
    bottomSheetContentRef.current?.addEventListener('touchmove', onTouchMove);
    bottomSheetContentRef.current?.addEventListener('touchend', onTouchEnd);
    return () => {
      bottomSheetContentRef.current?.removeEventListener('touchstart', onTouchStart);
      bottomSheetContentRef.current?.removeEventListener('touchmove', onTouchMove);
      bottomSheetContentRef.current?.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('resize', onResize);
    };
  });

  useEffect(() => {
    if (isInputActive && bottomSheetContentRef.current) {
      bottomSheetContentRef.current.style.top = `${52}px`;
    }
  }, [isInputActive]);

  /**
   * @returns current record. If id is passed then current record is record that user wants to edit else current record is record that user wants to order again or return trip
   */
  const getCurrentRecord = (): Ride => {
    const recordId = id
      ? id
      : (history?.location?.state as any)?.orderId
        ? (history.location.state as any).orderId
        : '';
    return ordersStore.list?.filter((item: any) => item.id === +recordId)[0];
  };

  /**
   * @param field key to try to get
   * @param order use as current record if passed
   * @returns if current record has value by key [field] then return an object with single key equals to field else {}
   */
  const getFieldIfExists = (field: keyof Ride, order: Ride | undefined = undefined): Partial<Ride> => {
    const record = order || getCurrentRecord();
    if (!record) return {};
    return record[field] ? { [field]: record[field] } : {};
  };

  /**the trip is in the opposite direction or order again */
  useEffect(() => {
    const { returnTrip, orderAgain, orderId, editing } = (history?.location?.state as any) || {};
    if ((returnTrip || orderAgain || editing) && orderId) {
      updateOrderByCurrentRecord(returnTrip);
    }
  }, [history?.location?.state]);

  /**
   * Get fields and values from current record and save them as values for currently edited\created order
   * @param returnTrip if "true" then the trip is in the opposite direction
   * @param orderAgain if "true" then order again
   */
  const updateOrderByCurrentRecord = async (
    returnTrip: boolean = false,
    orderAgain: boolean = false,
  ): Promise<void> => {
    const record = getCurrentRecord();
    let interimDest: any[] = record?.interim_destinations || [];
    const { customer_id, employee } = record || {};
    await rolesStore.fetchList(
      false,
      { customer_id, ...(employee?.role_id ? { id: employee.role_id } : { default: 1 }) },
      'id desc',
      0,
      100,
      true,
      false,
    );
    const role: Role = findRole(employee);
    let costCentersByRole = {};
    if (role) {
      setUserRole(role);
      const { cost_centers = {} } = getFieldIfExists('cost_centers', record);
      if (
        Object.keys(cost_centers).length > 0 &&
        (!rolesCostCentersStore?.cost_centers || !rolesCostCentersStore?.cost_centers?.length)
      ) {
        await rolesCostCentersStore.fetchFields({
          phone: employee?.phone,
          promo_id: record?.promo_id,
          customer_id: record?.customer_id,
          employee_id: record?.employee_id,
        });
      }
      const roleCostCentersNames = rolesCostCentersStore?.cost_centers?.map((cc) => cc?.name || '').filter((c) => !!c);
      costCentersByRole = roleCostCentersNames?.reduce(
        (acc, name) => (cost_centers[name] ? { ...acc, [name]: cost_centers[name] } : acc),
        {},
      );
    }
    setTimeout(() =>
      ordersStore.updateNewOrderInfo({
        ...(record
          ? {
              source: returnTrip ? record.destination : record.source,
              destination: returnTrip ? record?.source : record?.destination,
              class_id: record.class_id || 1,
            }
          : {}),
        interim_destinations: returnTrip && interimDest ? interimDest.reverse() : interimDest,
        cost_centers: costCentersByRole,
        ...getFieldIfExists('phone', record),
        ...getFieldIfExists('employee_id', record),
        ...getFieldIfExists('comment', record),
        ...getFieldIfExists('customer_id', record),
        ...getFieldIfExists('employee_name', record),
      }),
    );
    setIsGuest(!!record?.employee?.is_guest || false);
  };

  /**update new order info with values from current record if user edits order */
  useEffect(() => {
    if (id) {
      updateOrderByCurrentRecord();
    }
  }, [id]);

  const isValidPlaces = (): boolean =>
    destinations?.every((d) => !!d?.value?.address) && !!ordersStore.newOrderInfo?.source;

  useEffect(() => {
    document.body.classList.add('mobile-form-body');
    if (usersStore.role === 'employee') {
      selectEmployee(usersStore.me?.detail?.id as any, usersStore.me.detail);
    }
    return () => {
      ordersStore.resetNewOrderInfo();
      document.body.classList.remove('mobile-form-body');
    };
  }, []);

  const closeCalendar = (): void => {
    setIsOpenCalendar(false);
  };

  const updateScheduledTime = (v: Date): void => {
    ordersStore.updateNewOrderInfo({ scheduled_time: v ? v.getTime() / 1000 : null, scheduled_datetime: '' });
  };

  const getDate = (): string => {
    let date = '';
    if (ordersStore.newOrderInfo?.scheduled_datetime && ordersStore.newOrderInfo?.scheduled_time) {
      date = ordersStore.newOrderInfo?.scheduled_datetime;
    } else if (ordersStore.newOrderInfo?.scheduled_time) {
      date = formatDateValue(ordersStore.newOrderInfo?.scheduled_time, {
        format: 'DD.MM.YY / HH:mm',
        offset: ordersStore.newOrderInfo?.city_timezone_offset || moment().utcOffset() / 60,
      });
    }
    return date;
  };

  const getEstimateClass = (): TariffClass =>
    ordersStore?.estimateInfo?.classes?.find((cls) => cls.id === ordersStore.newOrderInfo.class_id) as TariffClass;

  const getUniqueProvidersGroups = (): Group[] | void => {
    const estimateClass: TariffClass = getEstimateClass();
    const { providers_info } = estimateClass || {};
    if (!estimateClass || !providers_info) return;
    const groups: UniqueProvidersGroups = Object.values(providers_info).reduce(
      (acc: UniqueProvidersGroups, val: ProviderInfo) => {
        if (!val.group?.id) return acc;
        return {
          ...acc,
          [val.group.id]: {
            ...val.group,
            prices: [...(acc[val.group.id]?.prices || []), val.price],
            eta_s: val.eta_s,
            priority: val.priority,
          },
        };
      },
      {},
    );
    return Object.values(groups).sort((gr1: Group, gr2: Group) => (gr1.priority || 0) - (gr2.priority || 0));
  };

  const renderProviderGroup = (group: Group, index: number, arr: any[]): JSX.Element | null => {
    let price: number = 0;
    if (group?.prices?.filter((pr) => !!pr)?.length > 0) {
      price = Math.min(...group.prices);
    }
    return (
      <li
        key={group?.id || index}
        className={`${arr.length - 1 !== index && 'mb-3'} custom-control custom-control-taxi`}
      >
        <input
          className="custom-control-input"
          defaultChecked={ordersStore.newOrderInfo?.provider_group_id === group.id}
          type="radio"
          id={`taxi-option-${index}`}
          name="taxi"
          onChange={() => ordersStore?.updateNewOrderInfo({ provider_group_id: group.id })}
        />
        <label className="custom-control-label" htmlFor={`taxi-option-${index}`}>
          <span className="custom-control-box custom-control-box-radio"></span>
          <span className="text-black">{group.name}</span>
          {/* <span className="ml-1 mr-2 flex items-center md:space-x-1 text-[0.8125rem] md:text-base font-medium text-black">
                    <svg className="w-5 h-5 md:w-6 md:h-6 text-yellow" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                        <path d="M11.424 3.99a.667.667 0 0 1 1.152 0l2.186 3.752a.667.667 0 0 0 .435.316l4.244.919c.5.108.696.714.356 1.095l-2.893 3.239a.667.667 0 0 0-.167.51l.438 4.321a.667.667 0 0 1-.932.678l-3.974-1.752a.666.666 0 0 0-.538 0L7.757 18.82a.667.667 0 0 1-.932-.678l.438-4.32a.666.666 0 0 0-.166-.511l-2.894-3.239a.667.667 0 0 1 .356-1.095l4.244-.92a.667.667 0 0 0 .435-.315l2.186-3.753Z"></path>
                    </svg>
                    <span>{provider.rating}</span>
                </span> */}
          {!!price && (
            <strong className="font-semibold ml-auto text-black">
              {t('provider_price_from')} {formatPrice(price)}
            </strong>
          )}
          {group.eta_s && (
            <span className="text-gray ml-2">
              <span className="mr-2">&#x2022;</span>
              {`${t('provider_time_from')} ${group.eta_s < 60 ? 1 : Math.round(group.eta_s / 60)} ${t('provider_time_minutes')}`}
            </span>
          )}
        </label>
      </li>
    );
  };

  const getMapValue = (place: Place) => {
    let address = '';
    const detail: any = {};
    place.address_components.reverse().forEach((item: any) => {
      if (
        // item.types.includes("country") ||
        // item.types.includes("administrative_area_level_1") ||
        // item.types.includes("administrative_area_level_2") ||
        item.types.includes('locality') ||
        item.types.includes('route') ||
        item.types.includes('street_number')
      ) {
        if (address !== '') address += ', ';
        address += item.long_name;
      }

      detail[item?.types[0]] = item.long_name;
    });
    const mapValue = {
      address,
      detail,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    return mapValue;
  };

  const onFavouritesSelect = (place: Place): void => {
    if (typeof currentDirectionRef.current === 'undefined') return;
    onMapChangeHandle(currentDirectionRef.current, { ...place, is_favourite: 1 }, true);
    setVisibleFavourites(false);
  };

  const onMapChangeHandle = (direction: Omit<CurrentField, 'unset'>, place: Place, completed: boolean = false) => {
    if (place?.id === 'favourites') {
      currentDirectionRef.current = direction;
      setVisibleFavourites(true);
      return;
    }
    let mapValue: any;
    if (!isEmpty(place)) {
      mapValue = completed ? place : getMapValue(place);
    }
    if (direction === 'source' || direction === 'destination') {
      if (!mapValue || isEmpty(place)) {
        ordersStore.resetNewOrderInfo();
        return;
      } else {
        ordersStore.updateCurrentFieldAndPlace('unset', { [direction as string]: mapValue });
        return;
      }
    }
    ordersStore.updateNewOrderInfo({
      interim_destinations: (newOrderInfo?.interim_destinations || []).map((d, i) => (i === direction ? place : d)),
    });
    mapValue && ordersStore?.updateCurrentField('unset');
  };

  const reorder = (order: number[]): void => {
    ordersStore.reorderInterimDestinations(order);
  };

  // try to find employee role or group default role
  const findRole = (empl: any) =>
    rolesStore?.list?.find((role: any) =>
      empl?.id === 0 || !empl?.role_id ? role.default : role.id === empl?.role_id,
    );

  const selectEmployee = async (id: number, item: any): Promise<void> => {
    ordersStore.updateNewOrderInfo({
      employee_id: id,
      phone: !item?.is_guest && item?.phone ? item?.phone : '',
    });
    // if list doesn't exist or doesn't contain employee role then fetch list by employee role_id value
    if (!(rolesStore.list && rolesStore.list.length && findRole(item)) && item && id) {
      await rolesStore.fetchList(
        false,
        { customer_id: item?.customer_id || undefined, ...(item?.role_id ? { id: item.role_id } : { default: 1 }) },
        'id desc',
        0,
        100,
        true,
        false,
      );
    } else if (item && item?.customer_id && item?.role_id) {
      await rolesStore.fetchList(
        false,
        { customer_id: item?.customer_id, id: item?.role_id },
        'id desc',
        0,
        100,
        true,
        false,
      );
    }
    const role = findRole(item) || rolesStore?.list?.find((role: any) => role.default);
    if (role) {
      setUserRole(role);
      ordersStore.updateNewOrderInfo({
        comment: role?.driver_comment || '',
      });
      if (item.phone) {
        setErrors((err: any) => omit(err, 'phone'));
      }
    }
    setIsGuest(item?.is_guest || false);
  };

  const selectCustomer = async (id: number, item: any): Promise<void> => {
    ordersStore.updateNewOrderInfo({ customer_id: id, employee_id: undefined, phone: '', cost_centers: {} });
    rolesStore.fetchList(false, { customer_id: id }, 'id desc', 0, 100, true, false);
    setUserRole(null);

    let customer;
    if (usersStore?.isOperator && id) {
      customer = ordersStore?.linkedList?.customer_id_id
        ? ordersStore.linkedList.customer_id_id.find((item: any) => item.id === id)
        : null;
    }
    if (customer?.manager_id) await managersStore.fetchRecord(customer?.manager_id);
    const manager = managersStore?.record;
    setCurrentManager(manager?.name);
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any, index: number): CSSProperties => ({
    opacity: 1,
    backgroundColor: 'rgb(242 242 242 )',
    ...(index === destinations.length - 1 ? { borderRadius: '0.5rem' } : {}),
    // height: isDragging ? 'calc(3rem * 1.2)' : '3rem',
    ...draggableStyle,
    width: isDragging ? `calc(${wrapperRef.current?.getBoundingClientRect().width}px * 1.1)` : draggableStyle.width,
    left: isDragging
      ? `calc(${draggableStyle.left}px - calc(${wrapperRef.current?.getBoundingClientRect().width}px * 0.1 / 2))`
      : undefined,
    boxShadow: isDragging ? '0px 10px 10px 0px rgba(0, 0, 0, .2), 0px -10px 10px 0px rgba(0, 0, 0, .2)' : 'none',
  });

  const deleteDestination = (e: any, index: number): void => {
    e.stopPropagation();
    if (index < destinations.length - 1) {
      ordersStore.deleteInterimDestination(index);
      return;
    }
    const { interim_destinations = [] } = newOrderInfo || {};
    if (interim_destinations.length < 1) return;
    ordersStore.updateNewOrderInfo({
      destination: interim_destinations[interim_destinations.length - 1],
      interim_destinations: [...interim_destinations].slice(0, interim_destinations.length - 1),
    });
  };

  const renderDestination = (provided: any, snapshot: any, item: any, index: number): JSX.Element => {
    const isInterim: boolean = index !== destinations.length - 1;
    return (
      <div
        key={item.id}
        className={`relative flex w-full`}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={!isVip ? getItemStyle(snapshot.isDragging, provided.draggableProps.style, index) : {}}
      >
        <AddressAutoComplete
          className="pl-1 border-0"
          placeholder={isInterim ? t('interim_destination') : t('destination')}
          onSelect={(place: any, completed: boolean = false) => {
            onMapChangeHandle(isInterim ? index : 'destination', place, completed);
            blurActiveInput();
          }}
          permanentОptions={favouritesItem}
          scrollToTopOnFocus={windowSizes.width < 992}
          defaultValue={item?.value?.address || ''}
          style={{
            flexShrink: 1,
            borderBottom: index !== destinations.length - 1 ? '1px solid rgba(0, 0, 0, 0.1' : 'none',
          }}
          inputId={item.id}
          onBlur={blurActiveInput}
          onFocus={() => {
            setIsInputActive(true);
            applyFullHeight();
            ordersStore.updateCurrentField(isInterim ? (index as CurrentField) : 'destination');
          }}
          leftIcon={() => (
            <>
              {isInterim ? (
                <div className="w-6 h-6 flex-shrink-0" style={{ margin: '0.75rem' }}>
                  <NumberIcon number={index + 1} />
                </div>
              ) : isVip ? (
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="2" y="2" width="10" height="10" fill="white" stroke="#0B0D0F" strokeWidth="4" />
                </svg>
              ) : (
                <div style={{ margin: '0.75rem' }}>
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M2 2.82387C2 2.82387 5.17647 1.41211 8 1.41211C10.8235 1.41211 13.3529 3.52976 16 3.52976C17.5218 3.52976 19.2768 2.94653 20.494 2.45066C21.1909 2.16674 22 2.6689 22 3.42141V15.0437C22 15.415 21.7951 15.755 21.4586 15.912C20.4035 16.4045 18.0002 17.4121 16 17.4121C13.3529 17.4121 10.4706 15.2945 8 15.2945C5.52941 15.2945 2 16.7062 2 16.7062V2.82387Z"
                      fill="#FDCD03"
                    ></path>
                    <rect x="2" width="2" height="24" rx="1" fill="#000"></rect>
                  </svg>
                </div>
              )}
            </>
          )}
          rightIcon={() => (
            <>
              {destinations.length > 1 && (
                <div className="flex">
                  <button type="button" className="cursor-move pl-3">
                    <MoveSvgIcon />
                  </button>
                </div>
              )}
              <div className="flex pr-3 pl-3">
                {isInterim || !item?.value?.address ? (
                  <button type="button" className="" style={{ left: 35 }} onClick={(e) => deleteDestination(e, index)}>
                    <DeleteSvgIcon />
                  </button>
                ) : (
                  <button type="button" onClick={onAddStop} disabled={isDisabledAddStop()}>
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                      <circle
                        cx="12"
                        cy="12"
                        r="12"
                        fill={isVip ? 'transparent' : isDisabledAddStop() ? 'rgba(0, 0, 0, 0.2)' : '#FDCD03'}
                      ></circle>
                      <path
                        d="M19 12L12 12L5 12"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12 19L12 12L12 5"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                )}
                <button type="button" className="ml-3 opacity-50 lg:hidden">
                  <svg
                    className="w-6 h-6"
                    onClick={(e) => onMap(isInterim ? index : 'destination', e)}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#444444"
                      d="M8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM13.2 5.3c0.4 0 0.7 0.3 1.1 0.3-0.3 0.4-1.6 0.4-2-0.1 0.3-0.1 0.5-0.2 0.9-0.2zM1 8c0-0.4 0-0.8 0.1-1.3 0.1 0 0.2 0.1 0.3 0.1 0 0 0.1 0.1 0.1 0.2 0 0.3 0.3 0.5 0.5 0.5 0.8 0.1 1.1 0.8 1.8 1 0.2 0.1 0.1 0.3 0 0.5-0.6 0.8-0.1 1.4 0.4 1.9 0.5 0.4 0.5 0.8 0.6 1.4 0 0.7 0.1 1.5 0.4 2.2-2.5-1.2-4.2-3.6-4.2-6.5zM8 15c-0.7 0-1.5-0.1-2.1-0.3-0.1-0.2-0.1-0.4 0-0.6 0.4-0.8 0.8-1.5 1.3-2.2 0.2-0.2 0.4-0.4 0.4-0.7 0-0.2 0.1-0.5 0.2-0.7 0.3-0.5 0.2-0.8-0.2-0.9-0.8-0.2-1.2-0.9-1.8-1.2s-1.2-0.5-1.7-0.2c-0.2 0.1-0.5 0.2-0.5-0.1 0-0.4-0.5-0.7-0.4-1.1-0.1 0-0.2 0-0.3 0.1s-0.2 0.2-0.4 0.1c-0.2-0.2-0.1-0.4-0.1-0.6 0.1-0.2 0.2-0.3 0.4-0.4 0.4-0.1 0.8-0.1 1 0.4 0.3-0.9 0.9-1.4 1.5-1.8 0 0 0.8-0.7 0.9-0.7s0.2 0.2 0.4 0.3c0.2 0 0.3 0 0.3-0.2 0.1-0.5-0.2-1.1-0.6-1.2 0-0.1 0.1-0.1 0.1-0.1 0.3-0.1 0.7-0.3 0.6-0.6 0-0.4-0.4-0.6-0.8-0.6-0.2 0-0.4 0-0.6 0.1-0.4 0.2-0.9 0.4-1.5 0.4 1.1-0.8 2.5-1.2 3.9-1.2 0.3 0 0.5 0 0.8 0-0.6 0.1-1.2 0.3-1.6 0.5 0.6 0.1 0.7 0.4 0.5 0.9-0.1 0.2 0 0.4 0.2 0.5s0.4 0.1 0.5-0.1c0.2-0.3 0.6-0.4 0.9-0.5 0.4-0.1 0.7-0.3 1-0.7 0-0.1 0.1-0.1 0.2-0.2 0.6 0.2 1.2 0.6 1.8 1-0.1 0-0.1 0.1-0.2 0.1-0.2 0.2-0.5 0.3-0.2 0.7 0.1 0.2 0 0.3-0.1 0.4-0.2 0.1-0.3 0-0.4-0.1s-0.1-0.3-0.4-0.3c-0.1 0.2-0.4 0.3-0.4 0.6 0.5 0 0.4 0.4 0.5 0.7-0.6 0.1-0.8 0.4-0.5 0.9 0.1 0.2-0.1 0.3-0.2 0.4-0.4 0.6-0.8 1-0.8 1.7s0.5 1.4 1.3 1.3c0.9-0.1 0.9-0.1 1.2 0.7 0 0.1 0.1 0.2 0.1 0.3 0.1 0.2 0.2 0.4 0.1 0.6-0.3 0.8 0.1 1.4 0.4 2 0.1 0.2 0.2 0.3 0.3 0.4-1.3 1.4-3 2.2-5 2.2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </>
          )}
        />
      </div>
    );
  };

  const onAddStop = (e: any): void => {
    e.stopPropagation();
    ordersStore.addInterimDestination({ address: '' });
  };

  const blurActiveInput = (): void => {
    setIsInputActive(false);
  };
  const onBlurPhone = useCallback(
    (field: string = '', placeholder: string = '', val?: string): void => {
      if (field === 'phone') {
        const phone = (val || ordersStore.newOrderInfo?.phone)?.replace(/\D/g, '');
        const validMask = placeholder.replace(/\D/g, '');
        if (phone.length < 5) return;
        if (
          !((phone && isValidPhone(phone, getCountryCca2(phone))) || (!phone && isGuest)) ||
          (phone && phone.length < validMask.length)
        ) {
          setErrors({
            ...errors,
            phone: t(phone.length < validMask.length ? 'error.phone_length' : 'error.phone', {
              ns: 'validation',
              count: validMask.length,
            }),
          });
        } else {
          const { phone, ...rest } = errors;
          setErrors(rest);
        }
      }
      //isValidPhone()
      setIsInputActive(false);
    },
    [ordersStore.newOrderInfo?.phone],
  );

  const isDisabledAddStop = (): boolean => {
    if (destinations.length === 0 || destinations.length === 6) return true;
    return destinations.findIndex((dest) => !dest.value?.address) >= 0;
  };

  const validate = (): boolean => {
    const { newOrderInfo } = ordersStore;
    let newErrors = {};
    const isPhoneCheck = !!(
      usersStore?.me?.role?.name === 'customer' ||
      usersStore?.me?.role?.name === 'operator' ||
      usersStore?.me?.role?.name === 'coordinator' ||
      (usersStore?.me?.role?.name === 'employee' && usersStore?.me?.detail?.can_order_others)
    );
    if ((!newOrderInfo?.phone || !isValidPhone(newOrderInfo?.phone?.replace(/\D/g, ''))) && isPhoneCheck) {
      newErrors = { ...newErrors, phone: t('error.phone', { ns: 'validation' }) };
    }
    if (!newOrderInfo?.employee_id && newOrderInfo?.employee_id !== 0) {
      newErrors = { ...newErrors, employee: t('error.required', { ns: 'validation' }) };
    }
    if (usersStore?.isOperator && !newOrderInfo?.customer_id) {
      newErrors = { ...newErrors, customer: t('error.required', { ns: 'validation' }) };
    }
    if (rolesCostCentersStore?.cost_centers) {
      rolesCostCentersStore.cost_centers.forEach((cc: any) => {
        newErrors =
          cc.required && !(newOrderInfo?.cost_centers && newOrderInfo.cost_centers[cc.name])
            ? { ...newErrors, [cc.name]: t('error.required', { ns: 'validation' }) }
            : newErrors;
      });
    }
    if (rolesCostCentersStore?.cost_centers) {
      rolesCostCentersStore.cost_centers.forEach((cc: any) => {
        newErrors =
          cc.type_id == 'string' &&
          cc.regexp &&
          newOrderInfo?.cost_centers &&
          newOrderInfo.cost_centers[cc.name] &&
          !newOrderInfo.cost_centers[cc.name].match(new RegExp(cc.regexp, 'giu'))
            ? {
                ...newErrors,
                [cc.name]: true,
              }
            : newErrors;
      });
    }
    if (isGuest && !newOrderInfo?.employee_name?.trim()) {
      newErrors = { ...newErrors, employee_name: t('error.required', { ns: 'validation' }) };
    }
    if (newOrderInfo?.class_id === 4) {
      if (!newOrderInfo?.phone_sender || !isValidPhone(newOrderInfo?.phone_sender)) {
        newErrors = { ...newErrors, phone_sender: t('error.phone', { ns: 'validation' }) };
      }
      if (!newOrderInfo?.phone_recipient || !isValidPhone(newOrderInfo?.phone_recipient)) {
        newErrors = { ...newErrors, phone_recipient: t('error.phone', { ns: 'validation' }) };
      }
    }
    setErrors(newErrors);
    return isEmpty(newErrors);
  };

  const onFocus = (field: string): void => {
    setErrors(omit(errors, field));
    setIsInputActive(true);
    if (windowSizes.width < 992) {
      applyFullHeight();
    }
  };

  // const updateOrder = (): void => {
  //     const record = getCurrentRecord();
  //     const d = new Date();
  //     const timezone_offset = -1*d.getTimezoneOffset();
  //     const {interim_destinations} = ordersStore.newOrderInfo || {}

  //     const updatedData = {
  //         ...ordersStore.newOrderInfo,
  //         ...(interim_destinations ? {interim_destinations: interim_destinations.slice(0, interim_destinations.length-1)} : {}),
  //         estimate: ordersStore.estimateInfo,
  //         timezone_offset,
  //         platform: APP_PLATFORM,
  //         version: APP_VERSION
  //     };
  //     record.update(updatedData);
  //     ordersStore.resetNewOrderInfo();
  // }

  const onMakeOrder = async (e: any): Promise<void> => {
    setIsCreating(true);
    const { newOrderInfo } = ordersStore || {};
    const { orderId, editing } = (history?.location?.state as any) || {};
    e.preventDefault();
    if (newOrderInfo?.class_id !== 4 && (newOrderInfo?.phone_sender || newOrderInfo?.phone_recipient)) {
      ordersStore.omitNewOrderInfoFields(['phone_sender', 'phone_recipient']);
    }
    const isValid = validate();
    const record = getCurrentRecord();
    const action = id ? t('changed') : t('added');
    ordersStore.updateCurrentField('unset');
    if (!isValid) {
      setIsCreating(false);
      return;
    }
    ordersStore.updateNewOrderInfo({
      phone: ordersStore.newOrderInfo.phone.replace(/\D/g, ''),
      comment: ordersStore.newOrderInfo?.comment?.trim(),
      ...(usersStore?.isPromo && promocodesStore?.chosenPromocode?.id
        ? {
            promo_id: promocodesStore?.chosenPromocode?.id,
          }
        : {}),
    });
    const isScheduled = ordersStore.newOrderInfo.scheduled_time > 0;

    if (id || (orderId && editing)) {
      await ordersStore.updateOrder(record.id);
    } else {
      await ordersStore.createOrder();
    }
    setIsCreating(false);
    if (ordersStore.addingError) {
      //@ts-ignore
      if (ordersStore.addingError.code === 409) {
        const confirmed = await confirm(ordersStore.addingError);
        if (confirmed) {
          const created = await ordersStore.createOrder({ skip_check_double: true });
          if (created?.id) {
            history.push('/customer/rides');
          }
        }
      } else {
        alert(ordersStore.addingError);
      }
    } else {
      if (isScheduled) {
        alert(t('success_msg', { action }));
      } else {
        // search car
      }
      history.push('/customer/rides');
    }
  };

  useEffect(() => {
    setEmployeeFilter(getEmployeeFilter());
  }, [usersStore?.me?.role?.name, ordersStore.newOrderInfo.customer_id]);

  useEffect(() => {
    setCustomerFilter(getCustomerFilter());
  }, [ordersStore.newOrderInfo.customer_id]);

  useEffect(() => {
    const phone = ordersStore.newOrderInfo?.phone?.replace(/\D/g, '');
    if ((phone && isValidPhone(phone, getCountryCca2(phone))) || (!phone && isGuest)) {
      rolesCostCentersStore.fetchFields({
        phone,
        promo_id: promocodesStore?.chosenPromocode?.id,
        customer_id: ordersStore.newOrderInfo?.customer_id,
        employee_id: ordersStore.newOrderInfo?.employee_id,
      });
    }
  }, [
    ordersStore.newOrderInfo.phone,
    promocodesStore?.chosenPromocode?.code,
    ordersStore.newOrderInfo?.customer_id,
    ordersStore.newOrderInfo?.employee_id,
    isGuest,
  ]);

  const getCustomerFilter = (): any => {
    const filter: any = {};
    if (ordersStore.newOrderInfo.customer_id || getFieldIfExists('customer_id').customer_id) {
      filter.id = ordersStore.newOrderInfo.customer_id || getFieldIfExists('customer_id').customer_id;
    }
    return filter;
  };

  const getEmployeeFilter = (): any => {
    const filter: any = {};
    if (usersStore?.me?.role?.name === 'operator') {
      if (ordersStore.newOrderInfo.customer_id || getFieldIfExists('customer_id').customer_id) {
        filter.customer_id = ordersStore.newOrderInfo.customer_id || getFieldIfExists('customer_id').customer_id;
      }
      if (ordersStore.newOrderInfo.employee_id || getFieldIfExists('employee_id').employee_id) {
        filter.id = ordersStore.newOrderInfo.employee_id || getFieldIfExists('employee_id').employee_id;
      }
    } else {
      filter.id = ordersStore.newOrderInfo.employee_id || getFieldIfExists('employee_id').employee_id;
    }
    if (usersStore?.me?.role?.name === 'employee' && usersStore?.me?.detail?.id) {
      filter.id = filter.id ? filter.id : usersStore?.me?.detail?.id;
      filter.customer_id = filter.customer_id ? filter.customer_id : usersStore?.me?.detail?.customer_id;
    }
    return filter;
  };

  const confirmAddressOnMap = (): void => {
    onMapChangeHandle(ordersStore.currentField, ordersStore.currentFieldValue as Place, true);
    if (!bottomSheetContentRef.current) return;
    bottomSheetContentRef.current.style.top = `${window.innerHeight - bottomSnap}px`;
    lastPositionRef.current = window.innerHeight - bottomSnap;
    setHiddenBotoomSheet(false);
  };

  const [currentManager, setCurrentManager] = useState(null);

  const openEntrance = (e) => {
    e.stopPropagation();
    setEntrance(ordersStore.newOrderInfo?.source?.entrance || '');
    setVisibleEntranceModal(true);
  };

  const closeEntranceWindow = () => {
    setVisibleEntranceModal(false);
    setEntrance('');
  };
  const applyEntranceWindow = () => {
    ordersStore.updateNewOrderInfo({
      source: {
        ...ordersStore.newOrderInfo.source,
        entrance,
      },
    });
    setVisibleEntranceModal(false);
    setEntrance('');
  };
  const changeEntrance = ({ target }) => setEntrance(target.value);

  return (
    <>
      {windowSizes.width < 992 ? (
        <>
          <div
            className="fixed"
            style={{
              zIndex: 3,
              backgroundColor: 'white',
              left: 0,
              paddingTop: 20,
              top: windowSizes.height - 170,
              bottom: 0,
              width: '100vw',
              borderTopLeftRadius: '0.5rem',
              borderTopRightRadius: '0.5rem',
            }}
          >
            <div className="mb-5 px-4 lg:px-5">
              <div className="lg:w-full md:mx-auto md:w-[30.25rem] relative border border-graystroke bg-graylight bg-opacity-94 rounded-lg">
                <div ref={wrapperRef} className="h-12 p-3">
                  {ordersStore.currentFieldValue?.address || ''}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`fixed flex ${hiddenBotoomSheet ? 'hidden' : ''}`}
            style={{
              zIndex: 3,
              backgroundColor: 'white',
              left: 0,
              paddingTop: 20,
              top: windowSizes.height - topSnap,
              bottom: 0,
              width: '100vw',
              borderTopLeftRadius: '0.5rem',
              borderTopRightRadius: '0.5rem',
            }}
            ref={bottomSheetContentRef}
          >
            <div
              ref={mapHandlerRef}
              className="flex justify-center w-full"
              style={{
                background: 'white',
                height: 20,
                top: 0,
                position: 'absolute',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
                zIndex: 1,
              }}
            >
              <div className="relative" style={{ width: 28, height: 3, marginTop: 5, backgroundColor: '#D5D5DD' }} />
            </div>
            <div ref={contentWrapperRef} className="pb-20" style={{ flex: 1 }}>
              <form className="h-full lg:h-auto lg:pb-0" style={{ height: 'auto' }}>
                <div className="mx-auto lg:w-full">
                  {usersStore?.me?.role?.name === 'operator' ? (
                    <div className="mb-5 px-4 lg:px-5">
                      <div className="lg:w-full lg:space-x-3 md:mx-auto md:w-[30.25rem]">
                        <ModalSelectStore
                          inputWrapperClass={`h-12 dark-form-control ${errors.customer ? 'validate-error' : ''}`}
                          inputClass="dark-form-control"
                          store={ordersStore}
                          fieldName={'customer_id'}
                          omitValuesOnFocus={['id']}
                          filter={customerFilter}
                          onOpen={() => {
                            onFocus('customer');
                          }}
                          onClose={() => setIsInputActive(false)}
                          fields={['id', 'name', 'manager_id']}
                          placeholder={t('customer')}
                          value={ordersStore.newOrderInfo.customer_id}
                          onChange={selectCustomer}
                          optionsWithId={usersStore?.isOperator}
                        />
                        {errors.customer && <span className="validate-error-message">{errors.customer}</span>}
                        {currentManager && (
                          <span className="manager-info-text">{`${t('manager')}: ${currentManager}`}</span>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {usersStore?.me?.role?.name === 'customer' ||
                  usersStore?.me?.role?.name === 'operator' ||
                  usersStore?.me?.role?.name === 'coordinator' ||
                  (usersStore?.me?.role?.name === 'employee' && usersStore?.me?.detail?.can_order_others) ? (
                    <div className="mb-5 px-4 lg:px-5">
                      <div className="lg:w-full lg:space-x-3 md:mx-auto md:w-[30.25rem]">
                        <ModalSelectStore
                          inputWrapperClass={`h-12 dark-form-control ${errors.employee ? 'validate-error' : ''}`}
                          inputClass="dark-form-control"
                          store={ordersStore}
                          fieldName={'employee_id'}
                          omitValuesOnFocus={['id']}
                          filter={employeeFilter}
                          onOpen={() => {
                            onFocus('employee');
                          }}
                          onClose={() => setIsInputActive(false)}
                          fields={['id', 'name', 'phone', 'role_id', 'is_guest', 'customer_id']}
                          orderBy={'is_guest desc'}
                          placeholder={t('employee')}
                          value={ordersStore.newOrderInfo.employee_id}
                          onChange={selectEmployee}
                          disabled={usersStore.isOperator && !employeeFilter.customer_id}
                        />
                        {errors.employee && <span className="validate-error-message">{errors.employee}</span>}
                      </div>
                    </div>
                  ) : null}
                  {isGuest && (
                    <div className="mb-5 px-4 lg:px-5">
                      <div className="lg:w-full md:mx-auto md:w-[30.25rem]">
                        <span className="form-label">{t('guest_full_name')}</span>
                        <input
                          className={`form-control form-control--lg ${errors.employee_name ? 'validate-error' : ''}`}
                          onChange={({ target }) =>
                            ordersStore.updateNewOrderInfo({ employee_name: handleEmployeeName(target.value, true) })
                          }
                          value={ordersStore.newOrderInfo?.employee_name || ''}
                          onFocus={() => onFocus('employee_name')}
                          placeholder={t('guest')}
                          onBlur={blurActiveInput}
                        />
                        {errors.employee_name && <span className="validate-error-message">{errors.employee_name}</span>}
                      </div>
                    </div>
                  )}
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:flex lg:space-x-3 lg:w-full md:mx-auto md:w-[30.25rem]">
                      {usersStore?.me?.role?.name === 'customer' ||
                      usersStore?.me?.role?.name === 'operator' ||
                      usersStore?.me?.role?.name === 'coordinator' ||
                      (usersStore?.me?.role?.name === 'employee' && usersStore?.me?.detail?.can_order_others) ? (
                        <div className="mb-5 lg:mb-0 lg:flex-1">
                          <label className="form-label" htmlFor="phone">
                            {t('phone_number')}
                          </label>
                          <PhoneInput
                            onChange={(value) => ordersStore.updateNewOrderInfo({ phone: value })}
                            value={ordersStore.newOrderInfo.phone || ''}
                            defaultValue={ordersStore.newOrderInfo.phone || ''}
                            onFocus={() => onFocus('phone')}
                            onBlur={onBlurPhone}
                            disabled={!isGuest}
                            name="phone"
                            className={`form-control form-control--lg ${errors.phone ? 'validate-error' : ''}`}
                          />
                          {errors.phone && <span className="validate-error-message block ml-3">{errors.phone}</span>}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:w-full md:mx-auto md:w-[30.25rem] relative border border-graystroke bg-graylight bg-opacity-94 rounded-lg">
                      <div ref={wrapperRef} className="">
                        <div className="relative min-h-12">
                          <AddressAutoComplete
                            className="pl-1 border-0"
                            defaultValue={
                              (ordersStore.newOrderInfo?.source?.address || '') +
                              (ordersStore.newOrderInfo?.source?.entrance
                                ? ', ' + t('entrance') + ' ' + ordersStore.newOrderInfo?.source?.entrance
                                : '')
                            }
                            placeholder={t('source')}
                            scrollToTopOnFocus
                            style={{ flexShrink: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.1' }}
                            onSelect={(place: any, completed: boolean = false) => {
                              onMapChangeHandle('source', place, completed);
                              blurActiveInput();
                            }}
                            permanentОptions={favouritesItem}
                            inputId="source_id"
                            leftIcon={() =>
                              isVip ? (
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="7" cy="7" r="5" fill="white" stroke="#0B0D0F" strokeWidth="4" />
                                </svg>
                              ) : (
                                <svg
                                  className="w-6 h-6 flex-shrink-0"
                                  style={{ margin: '0.75rem' }}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM9.51459 6.90881L16.5708 11.1425C17.2182 11.5308 17.2182 12.469 16.5709 12.8574L9.51465 17.0913C8.84812 17.4913 8.00014 17.0112 8.00014 16.2339L8.00011 7.76631C8.00011 6.98902 8.84807 6.50891 9.51459 6.90881Z"
                                    fill="#FDCD03"
                                  ></path>
                                  <path
                                    d="M16.5709 11.1424L9.51468 6.90873C8.84815 6.50883 8.00019 6.98894 8.00019 7.76623L8.00022 16.2338C8.00022 17.0111 8.84821 17.4912 9.51473 17.0913L16.571 12.8574C17.2183 12.4689 17.2183 11.5308 16.5709 11.1424Z"
                                    fill="#000"
                                  ></path>
                                </svg>
                              )
                            }
                            rightIcon={() => (
                              <button type="button" className="pr-3 shrink-1 opacity-50 lg:hidden"></button>
                            )}
                            onFocus={() => {
                              setIsInputActive(true);
                              applyFullHeight();
                              ordersStore.updateCurrentField('source');
                            }}
                            onBlur={blurActiveInput}
                            withEntrance
                          />
                        </div>
                        {destinations.length <= 1 ? (
                          <div className="relative min-h-12">
                            <AddressAutoComplete
                              className="pl-1 border-0"
                              defaultValue={
                                ordersStore.newOrderInfo?.destination?.address ||
                                ordersStore.newOrderInfo?.destination?.address ||
                                ''
                              }
                              placeholder={t('destination')}
                              style={{ flexShrink: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.1' }}
                              onSelect={(place: any, completed: boolean = false) => {
                                onMapChangeHandle('destination', place, completed);
                              }}
                              inputId="source_id"
                              permanentОptions={favouritesItem}
                              onFocus={() => ordersStore.updateCurrentField('destination')}
                              leftIcon={() =>
                                isVip ? (
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="10"
                                      height="10"
                                      fill="white"
                                      stroke="#0B0D0F"
                                      strokeWidth="4"
                                    />
                                  </svg>
                                ) : (
                                  <div style={{ margin: '0.75rem' }}>
                                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                                      <path
                                        d="M2 2.82387C2 2.82387 5.17647 1.41211 8 1.41211C10.8235 1.41211 13.3529 3.52976 16 3.52976C17.5218 3.52976 19.2768 2.94653 20.494 2.45066C21.1909 2.16674 22 2.6689 22 3.42141V15.0437C22 15.415 21.7951 15.755 21.4586 15.912C20.4035 16.4045 18.0002 17.4121 16 17.4121C13.3529 17.4121 10.4706 15.2945 8 15.2945C5.52941 15.2945 2 16.7062 2 16.7062V2.82387Z"
                                        fill="#FDCD03"
                                      ></path>
                                      <rect x="2" width="2" height="24" rx="1" fill="#000"></rect>
                                    </svg>
                                  </div>
                                )
                              }
                              rightIcon={() => (
                                <div className="flex pr-3 pl-3">
                                  <button type="button" onClick={onAddStop} disabled={isDisabledAddStop()}>
                                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        fill={
                                          isVip ? 'transparent' : isDisabledAddStop() ? 'rgba(0, 0, 0, 0.2)' : '#FDCD03'
                                        }
                                      ></circle>
                                      <path
                                        d="M19 12L12 12L5 12"
                                        stroke="#000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                      <path
                                        d="M12 19L12 12L12 5"
                                        stroke="#000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>
                                    </svg>
                                  </button>
                                  <button type="button" className="ml-3 opacity-50 lg:hidden">
                                    <svg
                                      className="w-6 h-6"
                                      onClick={(e) => onMap('destination', e)}
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill="#444444"
                                        d="M8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM13.2 5.3c0.4 0 0.7 0.3 1.1 0.3-0.3 0.4-1.6 0.4-2-0.1 0.3-0.1 0.5-0.2 0.9-0.2zM1 8c0-0.4 0-0.8 0.1-1.3 0.1 0 0.2 0.1 0.3 0.1 0 0 0.1 0.1 0.1 0.2 0 0.3 0.3 0.5 0.5 0.5 0.8 0.1 1.1 0.8 1.8 1 0.2 0.1 0.1 0.3 0 0.5-0.6 0.8-0.1 1.4 0.4 1.9 0.5 0.4 0.5 0.8 0.6 1.4 0 0.7 0.1 1.5 0.4 2.2-2.5-1.2-4.2-3.6-4.2-6.5zM8 15c-0.7 0-1.5-0.1-2.1-0.3-0.1-0.2-0.1-0.4 0-0.6 0.4-0.8 0.8-1.5 1.3-2.2 0.2-0.2 0.4-0.4 0.4-0.7 0-0.2 0.1-0.5 0.2-0.7 0.3-0.5 0.2-0.8-0.2-0.9-0.8-0.2-1.2-0.9-1.8-1.2s-1.2-0.5-1.7-0.2c-0.2 0.1-0.5 0.2-0.5-0.1 0-0.4-0.5-0.7-0.4-1.1-0.1 0-0.2 0-0.3 0.1s-0.2 0.2-0.4 0.1c-0.2-0.2-0.1-0.4-0.1-0.6 0.1-0.2 0.2-0.3 0.4-0.4 0.4-0.1 0.8-0.1 1 0.4 0.3-0.9 0.9-1.4 1.5-1.8 0 0 0.8-0.7 0.9-0.7s0.2 0.2 0.4 0.3c0.2 0 0.3 0 0.3-0.2 0.1-0.5-0.2-1.1-0.6-1.2 0-0.1 0.1-0.1 0.1-0.1 0.3-0.1 0.7-0.3 0.6-0.6 0-0.4-0.4-0.6-0.8-0.6-0.2 0-0.4 0-0.6 0.1-0.4 0.2-0.9 0.4-1.5 0.4 1.1-0.8 2.5-1.2 3.9-1.2 0.3 0 0.5 0 0.8 0-0.6 0.1-1.2 0.3-1.6 0.5 0.6 0.1 0.7 0.4 0.5 0.9-0.1 0.2 0 0.4 0.2 0.5s0.4 0.1 0.5-0.1c0.2-0.3 0.6-0.4 0.9-0.5 0.4-0.1 0.7-0.3 1-0.7 0-0.1 0.1-0.1 0.2-0.2 0.6 0.2 1.2 0.6 1.8 1-0.1 0-0.1 0.1-0.2 0.1-0.2 0.2-0.5 0.3-0.2 0.7 0.1 0.2 0 0.3-0.1 0.4-0.2 0.1-0.3 0-0.4-0.1s-0.1-0.3-0.4-0.3c-0.1 0.2-0.4 0.3-0.4 0.6 0.5 0 0.4 0.4 0.5 0.7-0.6 0.1-0.8 0.4-0.5 0.9 0.1 0.2-0.1 0.3-0.2 0.4-0.4 0.6-0.8 1-0.8 1.7s0.5 1.4 1.3 1.3c0.9-0.1 0.9-0.1 1.2 0.7 0 0.1 0.1 0.2 0.1 0.3 0.1 0.2 0.2 0.4 0.1 0.6-0.3 0.8 0.1 1.4 0.4 2 0.1 0.2 0.2 0.3 0.3 0.4-1.3 1.4-3 2.2-5 2.2z"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              )}
                            />
                          </div>
                        ) : null}
                        {newOrderInfo?.interim_destinations?.length > 0 ? (
                          <div className="relative">
                            <DnDList
                              onDragEnd={() => setIsBottomSheetDisabled(false)}
                              onDragStart={() => setIsBottomSheetDisabled(true)}
                              draggableProps={{ isDragDisabled: isInputActive }}
                              droppableProps={{ isDropDisabled: isInputActive }}
                              renderItem={renderDestination}
                              onReorder={reorder}
                              items={destinations}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:flex lg:space-x-3 lg:w-full md:mx-auto md:w-[30.25rem]">
                      <div className="mb-5 lg:mb-0 lg:flex-1">
                        <DateTimeField
                          fieldProps={{
                            title: t('scheduled_order'),
                            className: 'mb-4',
                          }}
                          showTimeSelect
                          timeIntervals={5}
                          onChange={updateScheduledTime}
                          dateFormat="DD.MM.YY / HH:mm"
                          {...(isToday(ordersStore.newOrderInfo?.scheduled_time * 1000)
                            ? {
                                minTime: today && setMinutes(today, 30),
                                maxTime: setHours(setMinutes(today, 45), 23),
                              }
                            : {})}
                          minDate={today}
                          selected={
                            ordersStore.newOrderInfo?.scheduled_time &&
                            new Date(ordersStore.newOrderInfo?.scheduled_time * 1000)
                          }
                          placeholderText={t('scheduled_order_placeholder')}
                        />
                      </div>
                    </div>
                  </div>
                  {usersStore?.isPromo ? (
                    <div className="mb-5 px-4 lg:px-5">
                      <div className="mb-5 lg:mb-0 lg:flex-1">
                        <div className="relative">
                          <div
                            onClick={() => setOpenPaymentMethodDialog(true)}
                            className="form-control form-control--lg items-center justify-between  flex"
                          >
                            <span>
                              {promocodesStore?.chosenPromocode?.id
                                ? `${t('payment_method')}: ${promocodesStore?.chosenPromocode?.code}`
                                : t('choose_payment_method')}
                            </span>
                            <div className="flex">
                              <button type="button" onClick={() => setOpenPaymentMethodDialog(true)}>
                                <svg
                                  width="6"
                                  height="10"
                                  viewBox="0 0 6 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ right: '3rem' }}
                                >
                                  <path
                                    d="M0.690008 9.50498C0.748065 9.56319 0.817034 9.60936 0.892965 9.64087C0.968897 9.67238 1.0503 9.6886 1.13251 9.6886C1.21472 9.6886 1.29612 9.67238 1.37205 9.64087C1.44798 9.60936 1.51695 9.56319 1.57501 9.50498L5.73 5.34999C5.77635 5.30373 5.81312 5.24879 5.83821 5.1883C5.86331 5.12782 5.87622 5.06297 5.87622 4.99749C5.87622 4.93201 5.86331 4.86716 5.83821 4.80668C5.81312 4.74619 5.77635 4.69125 5.73 4.64499L1.57501 0.489998C1.33001 0.244998 0.935006 0.244998 0.690007 0.489998C0.445007 0.734997 0.445008 1.13 0.690007 1.375L4.31 4.99999L0.685008 8.62498C0.445009 8.86498 0.445008 9.26498 0.690008 9.50498Z"
                                    fill="rgba(0, 0, 0, 0.4)"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {ordersStore.isEstimateInProgress && ordersStore?.newOrderInfo?.source ? (
                    <div className="mb-5 px-4 lg:px-5">
                      <div className="lg:w-full md:mx-auto md:w-[30.25rem]">{t('classes_loading')}...</div>
                    </div>
                  ) : null}
                  <Tariff
                    onPress={() => {
                      setErrors(omit(errors, ['phone_sender', 'phone_recipient']));
                    }}
                    className={`pr-4 pl-4 md:pr-0 md:pl-0 ${(getUniqueProvidersGroups() || [])?.length > 0 ? 'mb-5' : ''}`}
                  />
                  <div className="mb-5 px-4 lg:px-5">
                    <ul className="list-none m-0 p-0 lg:w-full md:mx-auto md:w-[30.25rem]">
                      {!isEmpty(getEstimateClass()?.providers_info || {})
                        ? (getUniqueProvidersGroups() || [])?.map(renderProviderGroup)
                        : null}
                      {(ordersStore.estimateInfo?.classes?.length || 0) > 0 && (
                        <p className="mt-1 mr-2 text-black text-right">
                          {t(
                            newOrderInfo?.promo_id || usersStore?.isPromo || usersStore?.isCostWithVat
                              ? 'vat'
                              : 'without_vat',
                          )}
                        </p>
                      )}
                    </ul>
                  </div>
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:w-full md:mx-auto md:w-[30.25rem]">
                      <RequirementsBlock containerClass="ml-2" />
                    </div>
                  </div>
                  {ordersStore?.newOrderInfo?.class_id === 4 ? (
                    <DeliveryFields
                      wrapperClass="mb-5 px-4 lg:px-5"
                      containerClass="lg:w-full lg:space-x-3 md:mx-auto md:w-[30.25rem]"
                      errors={errors}
                      onFocus={onFocus}
                    />
                  ) : null}
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:w-full md:mx-auto md:w-[30.25rem]">
                      <label className="form-label" htmlFor="class">
                        {t('wishes')}
                      </label>
                      <textarea
                        onChange={({ target }) => ordersStore?.updateNewOrderInfo({ comment: target.value })}
                        value={ordersStore.newOrderInfo?.comment || ''}
                        className="form-control form-control--lg min-h-[72px]"
                        rows={2}
                        cols={40}
                        placeholder={t('not_required', { ns: 'validation' })}
                        onFocus={() => {
                          setIsInputActive(true);
                          applyFullHeight();
                        }}
                        onBlur={blurActiveInput}
                      ></textarea>
                    </div>
                  </div>
                  {rolesCostCentersStore?.cost_centers && (
                    <CostCenters
                      wrapperClass="mb-5 px-4 lg:px-5"
                      containerClass="lg:w-full lg:space-x-3 md:mx-auto md:w-[30.25rem]"
                      costCenters={rolesCostCentersStore?.cost_centers}
                      errors={errors}
                      onFocus={onFocus}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
          {!isInputActive && (
            <div className="fixed left-0 z-10 w-full bottom-0 py-5 px-4 lg:px-5 bg-white lg:static lg:mt-7.5 lg:p-0 flex">
              <button
                className="btn btn-yellow btn-lg w-full md:mx-auto md:w-[30.25rem] lg:w-full"
                disabled={(hiddenBotoomSheet ? !hiddenBotoomSheet : !isValidPlaces()) || isCreating}
                onClick={hiddenBotoomSheet ? confirmAddressOnMap : onMakeOrder}
              >
                {hiddenBotoomSheet ? t('confirm', { ns: 'actions' }) : id ? t('actions.save') : t('actions.new_order')}
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="order-form flex">
          <div className="lg:py-5 lg:overflow-y-auto flex flex-col flex-grow">
            <h2 className="text-2lg leading-6 font-medium text-black mb-5 hidden lg:block px-4 lg:px-5">
              {t(
                id || ((history?.location?.state as any)?.orderId && (history?.location?.state as any)?.editing)
                  ? 'edit_title'
                  : 'title',
                { id: id || (history?.location?.state as any)?.orderId },
              )}
            </h2>
            <form className="h-full pb-20 lg:h-auto lg:pb-0 flex-grow flex flex-col justify-between">
              <div className="mx-auto lg:w-full">
                {usersStore?.me?.role?.name === 'operator' ? (
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:w-full lg:space-x-3 md:mx-auto md:w-[30.25rem]">
                      <ModalSelectStore
                        inputWrapperClass={`h-12 dark-form-control ${errors.customer ? 'validate-error' : ''}`}
                        inputClass="dark-form-control"
                        store={ordersStore}
                        fieldName={'customer_id'}
                        omitValuesOnFocus={['id']}
                        filter={customerFilter}
                        onOpen={() => {
                          onFocus('customer');
                          setIsInputActive(true);
                        }}
                        onClose={() => setIsInputActive(false)}
                        fields={['id', 'name', 'manager_id']}
                        placeholder={t('customer')}
                        value={ordersStore.newOrderInfo.customer_id}
                        onChange={selectCustomer}
                        optionsWithId={usersStore?.isOperator}
                      />
                      {errors.customer && <span className="validate-error-message">{errors.customer}</span>}
                      {currentManager && (
                        <span className="manager-info-text">{`${t('manager')}: ${currentManager}`}</span>
                      )}
                    </div>
                  </div>
                ) : null}

                {usersStore?.me?.role?.name === 'customer' ||
                usersStore?.me?.role?.name === 'operator' ||
                usersStore?.me?.role?.name === 'coordinator' ||
                (usersStore?.me?.role?.name === 'employee' && usersStore?.me?.detail?.can_order_others) ? (
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:w-full lg:space-x-3 md:mx-auto md:w-[30.25rem]">
                      <ModalSelectStore
                        inputWrapperClass={`h-12 dark-form-control ${errors.employee ? 'validate-error' : ''}`}
                        inputClass="dark-form-control"
                        store={ordersStore}
                        fieldName={'employee_id'}
                        omitValuesOnFocus={['id']}
                        filter={employeeFilter}
                        onOpen={() => {
                          onFocus('employee');
                          setIsInputActive(true);
                        }}
                        onClose={() => setIsInputActive(false)}
                        fields={['id', 'name', 'phone', 'role_id', 'is_guest', 'customer_id']}
                        orderBy={'is_guest desc'}
                        placeholder={t('employee')}
                        value={ordersStore.newOrderInfo.employee_id}
                        onChange={selectEmployee}
                        disabled={usersStore.isOperator && !employeeFilter.customer_id}
                      />
                      {errors.employee && <span className="validate-error-message">{errors.employee}</span>}
                    </div>
                  </div>
                ) : null}
                {isGuest && (
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:w-full md:mx-auto md:w-[30.25rem]">
                      <span className="form-label">{t('guest_full_name')}</span>
                      <input
                        className={`form-control form-control--lg ${errors.employee_name ? 'validate-error' : ''}`}
                        onChange={({ target }) =>
                          ordersStore.updateNewOrderInfo({ employee_name: handleEmployeeName(target.value, true) })
                        }
                        value={ordersStore.newOrderInfo?.employee_name || ''}
                        onFocus={() => onFocus('employee_name')}
                        placeholder={t('guest')}
                      />
                      {errors.employee_name && <span className="validate-error-message">{errors.employee_name}</span>}
                    </div>
                  </div>
                )}
                <div className="mb-5 px-4 lg:px-5">
                  <div className="lg:flex lg:space-x-3 lg:w-full md:mx-auto md:w-[30.25rem]">
                    {usersStore?.me?.role?.name === 'customer' ||
                    usersStore?.me?.role?.name === 'operator' ||
                    usersStore?.me?.role?.name === 'coordinator' ||
                    (usersStore?.me?.role?.name === 'employee' && usersStore?.me?.detail?.can_order_others) ? (
                      <div className="mb-5 lg:mb-0 lg:flex-1">
                        <label className="form-label" htmlFor="phone">
                          {t('phone_number')}
                        </label>
                        <PhoneInput
                          onChange={(value) => {
                            if (!isGuest) return;
                            ordersStore.updateNewOrderInfo({ phone: value });
                          }}
                          value={ordersStore.newOrderInfo.phone || ''}
                          defaultValue={ordersStore.newOrderInfo.phone || ''}
                          onFocus={() => onFocus('phone')}
                          onBlur={onBlurPhone}
                          disabled={!isGuest}
                          name="phone"
                          className={`form-control form-control--lg ${errors.phone ? 'validate-error' : ''}`}
                        />
                        {errors.phone && <span className="validate-error-message block ml-3">{errors.phone}</span>}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mb-5 px-4 lg:px-5">
                  <div className="lg:w-full md:mx-auto md:w-[30.25rem] relative border border-graystroke bg-graylight bg-opacity-94 rounded-lg">
                    <div ref={wrapperRef} className="">
                      <div className="relative min-h-12">
                        <AddressAutoComplete
                          className="pl-1 mr-1 border-0"
                          defaultValue={
                            (ordersStore.newOrderInfo?.source?.address || '') +
                            (ordersStore.newOrderInfo?.source?.entrance
                              ? ', ' + t('entrance') + ' ' + ordersStore.newOrderInfo?.source?.entrance
                              : '')
                          }
                          placeholder={t('source')}
                          style={{ flexShrink: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.1' }}
                          onSelect={(place: any, completed: boolean = false) => {
                            onMapChangeHandle('source', place, completed);
                          }}
                          inputId="source_id"
                          permanentОptions={favouritesItem}
                          onFocus={() => ordersStore.updateCurrentField('source')}
                          leftIcon={() =>
                            isVip ? (
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="7" cy="7" r="5" fill="white" stroke="#0B0D0F" strokeWidth="4" />
                              </svg>
                            ) : (
                              //play icon
                              <svg
                                className="w-6 h-6 flex-shrink-0"
                                style={{ margin: '0.75rem' }}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM9.51459 6.90881L16.5708 11.1425C17.2182 11.5308 17.2182 12.469 16.5709 12.8574L9.51465 17.0913C8.84812 17.4913 8.00014 17.0112 8.00014 16.2339L8.00011 7.76631C8.00011 6.98902 8.84807 6.50891 9.51459 6.90881Z"
                                  fill="#FDCD03"
                                ></path>
                                <path
                                  d="M16.5709 11.1424L9.51468 6.90873C8.84815 6.50883 8.00019 6.98894 8.00019 7.76623L8.00022 16.2338C8.00022 17.0111 8.84821 17.4912 9.51473 17.0913L16.571 12.8574C17.2183 12.4689 17.2183 11.5308 16.5709 11.1424Z"
                                  fill="#000"
                                ></path>
                              </svg>
                            )
                          }
                          rightIcon={() =>
                            ordersStore.newOrderInfo?.source?.address ? (
                              <div className="entrance-button mr-1" onClick={openEntrance}>
                                {t('entrance')}
                              </div>
                            ) : (
                              <div />
                            )
                          }
                          withEntrance
                        />
                      </div>
                      {destinations.length <= 1 ? (
                        <div className="relative min-h-12">
                          <AddressAutoComplete
                            className="pl-1 border-0"
                            defaultValue={
                              ordersStore.newOrderInfo?.destination?.address ||
                              ordersStore.newOrderInfo?.destination?.address ||
                              ''
                            }
                            placeholder={t('destination')}
                            style={{ flexShrink: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.1' }}
                            onSelect={(place: any, completed: boolean = false) => {
                              onMapChangeHandle('destination', place, completed);
                            }}
                            inputId="source_id"
                            permanentОptions={favouritesItem}
                            onFocus={() => ordersStore.updateCurrentField('destination')}
                            leftIcon={() =>
                              isVip ? (
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="2"
                                    y="2"
                                    width="10"
                                    height="10"
                                    fill="white"
                                    stroke="#0B0D0F"
                                    strokeWidth="4"
                                  />
                                </svg>
                              ) : (
                                <div style={{ margin: '0.75rem' }}>
                                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                                    <path
                                      d="M2 2.82387C2 2.82387 5.17647 1.41211 8 1.41211C10.8235 1.41211 13.3529 3.52976 16 3.52976C17.5218 3.52976 19.2768 2.94653 20.494 2.45066C21.1909 2.16674 22 2.6689 22 3.42141V15.0437C22 15.415 21.7951 15.755 21.4586 15.912C20.4035 16.4045 18.0002 17.4121 16 17.4121C13.3529 17.4121 10.4706 15.2945 8 15.2945C5.52941 15.2945 2 16.7062 2 16.7062V2.82387Z"
                                      fill="#FDCD03"
                                    ></path>
                                    <rect x="2" width="2" height="24" rx="1" fill="#000"></rect>
                                  </svg>
                                </div>
                              )
                            }
                            rightIcon={() => (
                              <div className="flex pr-3 pl-3">
                                <button type="button" onClick={onAddStop} disabled={isDisabledAddStop()}>
                                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="12"
                                      fill={
                                        isVip ? 'transparent' : isDisabledAddStop() ? 'rgba(0, 0, 0, 0.2)' : '#FDCD03'
                                      }
                                    ></circle>
                                    <path
                                      d="M19 12L12 12L5 12"
                                      stroke="#000"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M12 19L12 12L12 5"
                                      stroke="#000"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </button>
                              </div>
                            )}
                          />
                        </div>
                      ) : null}
                    </div>
                    {newOrderInfo?.interim_destinations?.length > 0 ? (
                      <div className="relative">
                        <DnDList
                          renderItem={renderDestination}
                          onReorder={reorder}
                          draggableProps={{ isDragDisabled: isInputActive }}
                          droppableProps={{ isDropDisabled: isInputActive }}
                          items={destinations}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mb-5 px-4 lg:px-5">
                  <div className="mb-5 lg:mb-0 lg:flex-1">
                    <DateTimeField
                      fieldProps={{
                        title: t('scheduled_order'),
                        className: 'mb-4',
                      }}
                      showTimeSelect
                      timeIntervals={5}
                      onChange={updateScheduledTime}
                      dateFormat="DD.MM.YY / HH:mm"
                      {...(isToday(ordersStore.newOrderInfo?.scheduled_time * 1000)
                        ? {
                            minTime: today && setMinutes(today, 30),
                            maxTime: setHours(setMinutes(today, 45), 23),
                          }
                        : {})}
                      minDate={today}
                      selected={
                        ordersStore.newOrderInfo?.scheduled_time &&
                        new Date(ordersStore.newOrderInfo?.scheduled_time * 1000)
                      }
                      placeholderText={t('scheduled_order_placeholder')}
                    />
                    {/* {usersStore?.me?.role?.name === 'operator' && ordersStore.newOrderInfo?.scheduled_time > 0 ? (
                                            <div style={{ padding: "5px 0px" }}>
                                                <input type='checkbox' onChange={(ev) => { ordersStore?.updateNewOrderInfo({ provider_type: ev.target.checked ? 'rt' : '' }) }} />&nbsp;
                                                Создать в Ритм
                                            </div>
                                        ) : null} */}
                  </div>
                </div>
                {usersStore?.isPromo ? (
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="mb-5 lg:mb-0 lg:flex-1">
                      <div className="relative">
                        <div
                          onClick={() => setOpenPaymentMethodDialog(true)}
                          className="form-control form-control--lg items-center justify-between  flex"
                        >
                          <span>
                            {promocodesStore?.chosenPromocode?.id
                              ? `${t('payment_method')}: ${promocodesStore?.chosenPromocode?.code}`
                              : t('choose_payment_method')}
                          </span>
                          <div className="flex">
                            <button type="button" onClick={() => setOpenPaymentMethodDialog(true)}>
                              <svg
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ right: '3rem' }}
                              >
                                <path
                                  d="M0.690008 9.50498C0.748065 9.56319 0.817034 9.60936 0.892965 9.64087C0.968897 9.67238 1.0503 9.6886 1.13251 9.6886C1.21472 9.6886 1.29612 9.67238 1.37205 9.64087C1.44798 9.60936 1.51695 9.56319 1.57501 9.50498L5.73 5.34999C5.77635 5.30373 5.81312 5.24879 5.83821 5.1883C5.86331 5.12782 5.87622 5.06297 5.87622 4.99749C5.87622 4.93201 5.86331 4.86716 5.83821 4.80668C5.81312 4.74619 5.77635 4.69125 5.73 4.64499L1.57501 0.489998C1.33001 0.244998 0.935006 0.244998 0.690007 0.489998C0.445007 0.734997 0.445008 1.13 0.690007 1.375L4.31 4.99999L0.685008 8.62498C0.445009 8.86498 0.445008 9.26498 0.690008 9.50498Z"
                                  fill="rgba(0, 0, 0, 0.4)"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {ordersStore.isEstimateInProgress && ordersStore?.newOrderInfo?.source ? (
                  <div className="mb-5 px-4 lg:px-5">
                    <div className="lg:w-full md:mx-auto md:w-[30.25rem]">{t('classes_loading')}...</div>
                  </div>
                ) : null}
                <Tariff
                  onPress={() => {
                    setErrors(omit(errors, ['phone_sender', 'phone_recipient']));
                  }}
                  className={`${(getUniqueProvidersGroups() || [])?.length > 0 ? 'mb-5' : ''} px-4 lg:px-5`}
                />
                <div className="mb-5 px-4 lg:px-5">
                  <ul className="list-none m-0 p-0 lg:w-full md:mx-auto md:w-[30.25rem]">
                    {!isEmpty(getEstimateClass()?.providers_info || {})
                      ? (getUniqueProvidersGroups() || [])?.map(renderProviderGroup)
                      : null}
                    {(ordersStore.estimateInfo?.classes?.length || 0) > 0 && (
                      <p className="mt-1 mr-2 text-black text-right">
                        {t(
                          newOrderInfo?.promo_id || usersStore?.isPromo || usersStore?.isCostWithVat
                            ? 'vat'
                            : 'without_vat',
                        )}
                      </p>
                    )}
                  </ul>
                </div>
                <div className="mb-5 px-4 lg:px-5">
                  <div className="lg:w-full md:mx-auto md:w-[30.25rem]">
                    <RequirementsBlock containerClass="ml-2" />
                  </div>
                </div>
                {ordersStore?.newOrderInfo?.class_id === 4 ? (
                  <DeliveryFields
                    wrapperClass="mb-5 px-4 lg:px-5"
                    containerClass="lg:w-full lg:space-x-3 md:mx-auto md:w-[30.25rem]"
                    errors={errors}
                    onFocus={onFocus}
                  />
                ) : null}
                <div className="mb-5 px-4 lg:px-5">
                  <div className="lg:w-full md:mx-auto md:w-[30.25rem]">
                    <label className="form-label" htmlFor="class">
                      {t('wishes')}
                    </label>
                    <textarea
                      onChange={({ target }) => ordersStore?.updateNewOrderInfo({ comment: target.value })}
                      value={ordersStore.newOrderInfo?.comment || ''}
                      className="form-control form-control--lg min-h-[72px]"
                      rows={2}
                      cols={40}
                      placeholder={t('not_required', { ns: 'validation' })}
                    ></textarea>
                  </div>
                </div>
                {rolesCostCentersStore?.cost_centers && (
                  <CostCenters
                    wrapperClass="mb-5 px-4 lg:px-5"
                    containerClass="lg:w-full lg:space-x-3 md:mx-auto md:w-[30.25rem]"
                    costCenters={rolesCostCentersStore?.cost_centers}
                    errors={errors}
                    onFocus={onFocus}
                  />
                )}
              </div>
              <div className="fixed z-10 left-0 w-full bottom-0 py-5 px-4 lg:px-5 bg-white lg:static lg:mt-7.5 lg:p-0 flex">
                <button
                  className="btn btn-yellow btn-lg w-full md:mx-auto md:w-[30.25rem] lg:w-full"
                  disabled={
                    !isValidPlaces() ||
                    isCreating ||
                    !ordersStore.estimateInfo?.classes ||
                    !ordersStore.estimateInfo?.classes.length ||
                    (ordersStore.isEstimateInProgress && ordersStore?.newOrderInfo?.source)
                  }
                  onClick={onMakeOrder}
                >
                  {id ? t('actions.save') : t('actions.new_order')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <FavouritesModal
        visible={visibleFavourites}
        title={t('favourites')}
        onClose={() => setVisibleFavourites(false)}
        onSelect={onFavouritesSelect}
      />
      {usersStore?.isPromo ? (
        <>
          <PaymentMethodDialog
            visible={isOpenPaymentMethodDialog}
            onCancel={() => setOpenPaymentMethodDialog(false)}
            onClose={() => setOpenPaymentMethodDialog(false)}
            toActivateDialog={() => setActivatePromocodeDialog(true)}
            ordersStore={ordersStore}
          />
          <ActivateDialog
            visible={activatePromocodeDialog}
            onCancel={() => setActivatePromocodeDialog(false)}
            onClose={() => setActivatePromocodeDialog(false)}
            toPaymentMethodeDialog={() => setOpenPaymentMethodDialog(true)}
            ordersStore={ordersStore}
          />
        </>
      ) : null}
      <ModalBase visible={visibleEntranceModal} title={t('entrance')} onClose={closeEntranceWindow}>
        <input
          className={`form-control form-control--lg ${errors.employee_name ? 'validate-error' : ''}`}
          onChange={changeEntrance}
          value={entrance || ''}
          placeholder={t('entrance')}
        />
        <div className="z-10 left-0 w-full bottom-0 py-5 bg-white lg:static lg:mt-7.5 lg:p-0 flex">
          <button className="btn btn-yellow w-full" onClick={applyEntranceWindow}>
            {t('actions.done')}
          </button>
        </div>
      </ModalBase>
    </>
  );
});

export default Form;
