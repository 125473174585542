import { CURRENT_CURRENCY } from 'constants/currenciesSigns';
import { COST_CENTER_DATE } from 'constants/formats';
import { CANCELLED, COMPLETE, ERROR, EXPIRED, FAILED, FEEDBACK, SCHEDULED } from 'constants/orderStatuses';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStores } from 'stores';
import { Place, Ride } from 'types/types';
import { formatDateValue } from 'utils/lib/formatDateValue';
import { formatPerformerPhone, formatPhone } from 'utils/lib/formatPhone';
import { isRideCancelable } from 'utils/lib/isRideCancelable';
import { getCoords } from 'utils/lib/map/getCoords';
import OrderRoute from './OrderRoute';
import { Li } from './li';
import { isRideCallable } from 'utils/lib/isRideCallable';
import { isRideActive } from '../../utils/lib/isRideActive';
import { CopyButton } from '../../components/Buttons/CopyButton';
import { formatPrice } from '../../utils/lib/formatPrice';
import { CancelledByUserId } from 'types/enums';
import { getRideTime } from './tableRow';
import CopiedText from '../../components/CopiedText';
import { getStatusClassAndTitle } from '../../utils/lib/getStatusClassAndTitle';
import { CurrencySign } from '../../components/TariffCard';
import { CostCenter } from '../Policy/costCentersDialog';

interface DetailsProps {
  selected: boolean;
  ride: Ride;
  costCenters: CostCenter[];
}

const fullTime = 'DD.MM.YY / HH:mm';
const time = 'HH:mm:ss';

const Details = observer(({ selected, ride, costCenters }: DetailsProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { t } = useTranslation('rides');
  const { usersStore, ordersStore, providersStore } = useStores();
  const mapRef = useRef<any>(null);
  const [rowHeight, setRowHwight] = useState<string>('0px');

  const [fields, setFields] = useState({});
  // sets row height (if selected by its content else 0)
  const calcRowHeight = () => setRowHwight(selected ? 'auto' : '0px');
  const [classSuffix] = getStatusClassAndTitle(ride.status_id as string);

  useEffect(() => {
    calcRowHeight();
  }, [selected]);

  useEffect(() => {
    if (costCenters?.length > 0) {
      const fieldList: any = ride?.cost_centers
        ? Object.keys(ride?.cost_centers).reduce((res, key) => {
            const name = ride?.cost_centers[key]?.name || key;
            let value =
              ride?.cost_centers[name] && typeof ride?.cost_centers[name] === 'object'
                ? ride?.cost_centers[name].value
                : ride?.cost_centers[name];
            const [f] = costCenters.filter((c) => c.name === name);
            if (f?.type_id === 'date') value = moment(value * 1000).format('DD.MM.YYYY');
            return { ...res, [name]: value };
          }, {})
        : [];

      setFields(fieldList);
    }
  }, [costCenters?.length, ride?.cost_centers]);

  const onCancel = (e: any): void => {
    e.stopPropagation();
    ride.update({ status_id: CANCELLED });
  };

  const publishToRitm = (e: any): void => {
    e.stopPropagation();
    ordersStore.publishOrder(ride?.id!, { provider: 'rt' });
  };
  const reorder = (e: any): void => {
    e.stopPropagation();
    ordersStore.repost(ride?.id!);
  };
  const showDriverPhone = (e: any): void => {
    e.stopPropagation();
    ordersStore.getDriverPhone(ride?.id as string);
  };

  const onEdit = (e: any): void => {
    e.stopPropagation();
    history.push({
      pathname: '/customer/new-order',
      state: {
        orderId: ride?.id,
        editing: true,
      },
    });
  };

  const getDate = (): string => {
    let date = '';
    if (ride?.scheduled_datetime) {
      date = ride?.scheduled_datetime;
    } else if (ride?.scheduled_time) {
      date = formatDateValue(ride.scheduled_time, { format: 'DD.MM.YY на HH:mm', offset: ride?.city_timezone_offset });
    }
    return date;
  };

  /**
   * Chech if the order can no longer be active
   * @returns false - order can be active (status - scheduled, new, search, ...)
   */
  const canOrderAgain = (): boolean =>
    [ERROR, COMPLETE, FEEDBACK, CANCELLED, FAILED, EXPIRED].includes(ride?.status_id!);

  const onOrderAgain = (): void => {
    history.push({
      pathname: '/customer/new-order',
      state: {
        orderId: ride?.id,
        orderAgain: true,
      },
    });
  };

  const onReturnTrip = (): void => {
    history.push({
      pathname: '/customer/new-order',
      state: {
        orderId: ride?.id,
        returnTrip: true,
      },
    });
  };
  const filterByEmployee = (): void => {
    const name = ride?.employee?.is_guest ? ride?.employee_name || '' : ride?.employee?.name || '';
    if (name) {
      ordersStore.setFilter(
        false,
        { employee_name: ['like', name] },
        'id desc',
        0,
        ordersStore?.limit || 10,
        true,
        false,
        true,
      );
    }
  };

  const onReceipt = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    ordersStore.getReceipt(+ride?.id!);
  };

  /**
   * print all cost centers
   * @returns a fragment with an array of <li> elements containing cost center name and value
   */
  const renderCostCenters = (): JSX.Element | null =>
    isEmpty(Object.keys(fields) || []) ? null : (
      <>
        {Object.keys(fields)?.map((key: any, i: number) =>
          key ? (
            <Li className="flex" key={`cost-center-${i}`} customTitle={key}>
              {fields[key]}
            </Li>
          ) : null,
        )}
      </>
    );

  /**
   * print interim destinations
   * @returns a fragment with an array of <li> elements containing interim destination name and value
   */
  const renderInterimDests = (): JSX.Element | null =>
    (ride?.interim_destinations?.length || 0) > 0 ? (
      <>
        {ride.interim_destinations!.map((dest: any, i: number) =>
          dest?.address ? (
            <Li
              onClick={(e) => onPlacePress(e, dest)}
              titleClass="w-24"
              customTitle={`${t('details.interim_destination')} ${i + 1}`}
            >
              {dest.address || ''}
            </Li>
          ) : null,
        )}
      </>
    ) : null;

  /**
   * Move map center to passed place
   * @param e onClick event
   * @param place can be source, interim_destination or destination
   */
  const onPlacePress = (e, place: Place): void => {
    e.stopPropagation();
    if (!mapRef?.current) return;
    mapRef.current?.setCenter(getCoords(place));
  };
  const getRideLink = async (): Promise<string> => {
    return await ordersStore.getRideLink(+ride?.id!);
  };

  const getStatusText = (): string | undefined => {
    if (ride.status_id !== CANCELLED) return undefined;
    switch (true) {
      case (ride?.cancelled_by_user_id || 0) > 0:
        return ride?.cancelled_by || t('details.cancelled_client');
      case (ride?.cancelled_by_user_id || 0) === CancelledByUserId.Driver:
        return t('details.cancelled_driver');
      case (ride?.cancelled_by_user_id || 0) === CancelledByUserId.DriverNotFound:
        return t('details.driver_not_found');
    }
  };

  const renderCost = () => {
    const isWithVat = ride?.promo_id || usersStore?.isPromo || usersStore?.isCostWithVat;
    const cost = isWithVat
      ? ride?.cost_detail?.customer_total_estimate_cost_inc_tax
      : ride?.cost_detail?.customer_total_estimate_cost_exc_tax;

    return ride?.status_id !== COMPLETE &&
      ride?.status_id !== CANCELLED &&
      (cost || cost === 0 || ride?.cost_customer_estimate) &&
      ride?.performer?.car ? (
      <Li tKey={`cost_customer_estimate${isWithVat ? '_inc_' : '_exc_'}tax`}>
        {formatPrice(cost && cost >= 0 ? cost : ride?.cost_customer_estimate)}
      </Li>
    ) : null;
  };

  const copyToClipboard = (e, text: string = '') => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(text || '');
  };

  return (
    <div
      ref={containerRef}
      className="overflow-hidden transition-all h-0 duration-300"
      style={{ height: rowHeight, maxHeight: rowHeight === '0px' ? '0px' : 'unset', transitionProperty: 'max-height' }}
    >
      <div className="md:w-full md:flex md:items-start px-4 md:pl-0 xl:pr-5">
        <div className="md:p-4 md:pr-5 xl:px-5">
          <div className="w-72 h-72 md:w-[248px] md:h-[248px]" onClick={(e) => e.stopPropagation()}>
            {selected && <OrderRoute mapRef={mapRef} record={ride} />}
          </div>
        </div>
        <div className="flex-grow md:border-t md:border-t-graystroke py-4">
          <div className="flex md:flex-row">
            <ul className="list-none m-0 p-0">
              <Li className="py-0" titleClass="w-24" tKey="id">
                {ride?.id || ''}
              </Li>
              {usersStore?.isOperator &&
              (ride?.customer?.manager?.name ||
                (usersStore?.me?.detail?.customer_id === ride?.customer_id &&
                  usersStore?.me?.detail?.manager?.name)) ? (
                <Li className="py-0" titleClass="w-24" tKey="manager">
                  {ride?.customer?.manager?.name || usersStore?.me?.detail?.manager?.name || ''}
                </Li>
              ) : null}
              <Li tKey="status" titleClass="w-24">
                <span className={`status ride-${classSuffix}`}>{t(`status.${ride.status_id}`, { ns: 'order' })}</span>
              </Li>
              {ride?.cancelled_time && (
                <Li tKey="cancelled_time">
                  {formatDateValue(ride.cancelled_time, {
                    format: fullTime,
                    offset: ride?.city_timezone_offset,
                  })}
                </Li>
              )}
              {ride?.start_transporting_time && (
                <Li tKey="start_transporting_time">
                  {formatDateValue(ride.start_transporting_time, {
                    format: time,
                    offset: ride?.city_timezone_offset,
                  })}
                </Li>
              )}
              {ride?.status_id === COMPLETE && ride?.finished_time && (
                <Li tKey="finished_time">
                  {formatDateValue(ride.finished_time, { format: time, offset: ride?.city_timezone_offset })}
                </Li>
              )}
              {!!getStatusText() && <Li tKey="cancelled_by">{getStatusText()}</Li>}
              {(!!ride.cuser?.name || ride.platform === 'support') && (
                <Li tKey="orderer">{ride.platform === 'support' ? t('trow.support') : ride.cuser.name}</Li>
              )}
              {ride?.ctime && (
                <Li tKey="order_time">
                  {formatDateValue(ride.ctime, { format: fullTime, offset: ride?.city_timezone_offset })}
                </Li>
              )}
              {!!(isRideCancelable(ride?.status_id!) && (ride?.scheduled_time || ride?.scheduled_datetime)) && (
                <Li tKey="time" titleClass="w-24">
                  {getDate()}
                </Li>
              )}
              <Li onClick={(e) => onPlacePress(e, ride?.source! as Place)} titleClass="w-24" tKey="source">
                {ride?.source?.address || ''}
              </Li>
              <Li onClick={(e) => onPlacePress(e, ride?.source! as Place)} titleClass="w-24" tKey="destination">
                {ride?.destination?.address || ''}
              </Li>
              {renderInterimDests()}
              <Li titleClass="w-24" tKey="employee">
                {ride?.employee?.is_guest
                  ? `${ride?.employee_name || ''} ${t('trow.guest')}`
                  : ride?.employee?.name || ''}
              </Li>
              <Li titleClass="w-24" tKey="phone">
                <CopiedText
                  text={formatPhone(ride?.phone || ride?.employee?.phone || '')}
                  textToCopy={ride?.phone || ride?.employee?.phone || ''}
                />
              </Li>
              <Li titleClass="w-24" tKey="class">
                {ride?.class?.title || ''}
              </Li>
              {ride?.route?.is_toll_road && (
                <Li titleClass="w-24" tKey="is_toll_road">
                  <CurrencySign />
                  {t(
                    ride?.route?.is_toll_road_confirmed
                      ? 'details.is_toll_road_paid_by_driver'
                      : 'details.is_toll_road_paid_by_client',
                  )}
                </Li>
              )}
              {ride?.performer?.car && (
                <Li titleClass="w-24" tKey="car">
                  {ride?.performer?.car
                    ?.split(' ')
                    .slice(0, ride?.performer?.car?.split(' ')?.length - 1)
                    .join(' ')}{' '}
                  / {ride?.performer?.car?.split(' ').pop()} ({ride?.performer?.fullname || ''})
                  {usersStore?.isOperator && ride?.performer?.phone ? (
                    <div>
                      <CopiedText
                        text={formatPerformerPhone(ride?.performer?.phone)}
                        textToCopy={ride?.performer?.phone}
                      />
                    </div>
                  ) : null}
                </Li>
              )}
              {(usersStore?.isOperator && ride?.performer?.phone) ||
              isRideCallable(ride?.status_id!, ride?.finished_time) ? (
                <Li titleClass="w-24" tKey="driver_phone">
                  {usersStore?.isOperator && ride?.performer?.phone ? (
                    <CopiedText
                      text={formatPerformerPhone(ride?.performer?.phone)}
                      textToCopy={ride?.performer?.phone}
                    />
                  ) : !ride?.driver_phone ? (
                    <button
                      className="status ride-active min-w-24 mr-2"
                      onClick={showDriverPhone}
                      disabled={ordersStore.isGettingPhone}
                    >
                      {t('details.actions.show')}
                    </button>
                  ) : (
                    <CopiedText text={formatPerformerPhone(ride?.driver_phone)} textToCopy={ride?.driver_phone} />
                  )}
                </Li>
              ) : null}
              {renderCost()}
              {usersStore?.isOperator && (
                <Li customTitle={t('thead.cost')}>
                  {ride?.cost_customer_with_vat ? formatPrice(+ride.cost_customer_with_vat, '0,0.[00]', 2) : '-'}
                </Li>
              )}
              {!!ride?.cost_customer && (
                <Li tKey="cost_customer">
                  {parseFloat((Number(ride?.cost_customer) || 0)?.toFixed(2)) + ` ${CURRENT_CURRENCY}`}
                </Li>
              )}
              {!!ride?.cost_customer_with_vat && (
                <Li className="items-end" tKey="cost_customer_with_vat">
                  {parseFloat((Number(ride?.cost_customer_with_vat) || 0)?.toFixed(2)) + ` ${CURRENT_CURRENCY}`}
                </Li>
              )}
              {!!ride?.paid_waiting_time_s && (
                <Li titleClass="w-24" tKey="paid_waiting_time_s">
                  {Math.ceil(Number(ride.paid_waiting_time_s) / 60)} {t('trow.minutes')}
                </Li>
              )}
              {usersStore?.isOperator && ride?.paid_waiting_time_s && ride.transporting_time_s ? (
                <>
                  <div>
                    {t('trow.paid_waiting')}:{' '}
                    <span className="text-gray">
                      {Math.ceil(ride.paid_waiting_time_s / 60)} {t('trow.minutes_short')}.
                    </span>
                  </div>
                  <div>
                    {t('trow.ride')}:{' '}
                    <span className="text-gray">
                      {getRideTime(ride)} {t('trow.minutes_short')}.
                    </span>
                  </div>
                </>
              ) : null}
              {usersStore?.isOperator ? (
                <Li className="py-0" customTitle={t('thead.time')}>
                  {ride?.paid_waiting_time_s && ride.transporting_time_s ? (
                    <>
                      <div>
                        {t('trow.paid_waiting')}:{' '}
                        <span className="text-gray">
                          {Math.ceil(ride.paid_waiting_time_s / 60)} {t('trow.minutes_short')}.
                        </span>
                      </div>
                      {getRideTime(ride) > 0 && (
                        <div>
                          {t('trow.ride')}:{' '}
                          <span className="text-gray">
                            {getRideTime(ride)} {t('trow.minutes_short')}.
                          </span>
                        </div>
                      )}
                    </>
                  ) : null}
                  {ride?.arrival_time_s && ride.status_id === 'driving' ? (
                    <div>
                      {t('trow.arrival_time')}:{' '}
                      <span className="text-gray">
                        {Math.ceil(ride.arrival_time_s / 60)} {t('trow.minutes_short')}.
                      </span>
                    </div>
                  ) : null}
                  {ride.transporting_time_s ? (
                    <div>
                      {t('trow.full_time')}:{' '}
                      <span className="text-gray">
                        {Math.ceil(ride.transporting_time_s / 60)} {t('trow.minutes_short')}.
                      </span>
                    </div>
                  ) : null}
                </Li>
              ) : null}
            </ul>
            <div className="ml-10">
              <b className="mb-2 block">{t('details.additional_fields')}:</b>
              <ul className="list-none m-0 p-0 space-y-2 ">
                {renderCostCenters()}
                {
                  <Li className="py-0" tKey="customer_name">
                    {ride?.customer?.name || usersStore?.me?.detail?.customer?.name || ''}
                  </Li>
                }
                <Li className="py-0" tKey="comment">
                  {ride?.comment || ' - '}
                </Li>
                {isRideActive(ride?.status_id!) ? (
                  <Li className="py-0" tKey="guest_link">
                    <CopyButton className="status ride-active mr-2 min-w-24" copyText="" getCopiedText={getRideLink} />
                  </Li>
                ) : null}
                {usersStore?.isOperator && (
                  <Li className="py-0" tKey="internal_comment">
                    {ride?.internal_comment || ' - '}
                  </Li>
                )}
                {usersStore?.isOperator && (
                  <Li className="py-0" tKey="provider">
                    {ride?.provider_info?.provider_type}:
                    {providersStore.getProviderName(ride?.provider_info?.provider_type) || ' - '}
                  </Li>
                )}

                {usersStore?.isOperator && ride?.provider_info?.provider_type !== 'oe' && (
                  <Li className="py-0" tKey="provider_order_id">
                    {ride?.provider_info?.provider_order_id || ' - '}
                  </Li>
                )}
                {canOrderAgain() && (
                  <li className="flex">
                    <button className="status ride-active min-w-24 mr-2" onClick={onOrderAgain}>
                      {t('details.actions.order_again')}
                    </button>
                    <button className="status ride-active mr-2 min-w-24" onClick={onReturnTrip}>
                      {t('details.actions.return_trip')}
                    </button>
                    <button className="status ride-active mr-2 min-w-24" onClick={filterByEmployee}>
                      {t('details.actions.employee_rides')}
                    </button>
                    {(usersStore?.isOperator && ride?.customer?.is_receipt_available) ||
                    (usersStore?.me?.detail?.customer_id === ride?.customer_id &&
                      usersStore?.me?.detail?.customer?.is_receipt_available) ? (
                      <button className="status ride-active mr-2 min-w-24" onClick={onReceipt}>
                        {t('details.actions.receipt')}
                      </button>
                    ) : null}
                  </li>
                )}
                {isRideCancelable(ride?.status_id!) && (
                  <li className="flex py-1">
                    <button className="status ride-inactive w-24 mr-2" onClick={onCancel}>
                      {t('details.actions.cancel')}
                    </button>
                    {/* {(ride?.status_id === 'scheduled') && (<button className='status ride-active mr-2 min-w-24' onClick={onEdit}>{t('details.actions.edit')}</button>)} */}
                  </li>
                )}
                {ride?.status_id === SCHEDULED && usersStore?.isOperator && !ride?.provider_type && (
                  <li className="flex py-1">
                    <button className="status ride-active min-w-24 mr-2" onClick={publishToRitm}>
                      {t('details.actions.ritm')}
                    </button>
                  </li>
                )}
                {usersStore?.isOperator && (ride?.cancelled_by_user_id || 0) === CancelledByUserId.DriverNotFound ? (
                  <li className="flex py-1">
                    <button
                      disabled={ordersStore?.isReordering}
                      className="status ride-active min-w-24 mr-2"
                      onClick={reorder}
                    >
                      {t('details.actions.reorder')}
                    </button>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Details;
