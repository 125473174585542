import React from 'react';
import InputMask, { Props } from 'react-input-mask';

interface MaskedInputProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onPaste?: (any) => void;
}

const MaskedInput = ({ inputProps, onPaste, ...rest }: MaskedInputProps & Props): JSX.Element => {
  return (
    <InputMask onPaste={onPaste} {...rest}>
      {(passedProps: any) => <input {...passedProps} />}
    </InputMask>
  );
};

export default MaskedInput;
