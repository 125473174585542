import { CURRENT_CURRENCY } from 'constants/currenciesSigns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { Ride } from 'types/types';
import { formatPerformerPhone, formatPhone } from 'utils/lib/formatPhone';
import { getStatusClassAndTitle } from 'utils/lib/getStatusClassAndTitle';
import Details from './details';
import { formatPrice } from '../../utils/lib/formatPrice';
import { formatAddress } from '../../utils/lib/formatAddress';
import CopiedText from '../../components/CopiedText';
import { CurrencySign } from '../../components/TariffCard';
import { formatDateValue } from '../../utils/lib/formatDateValue';
import { ReactComponent as PromoIcon } from '../../assets/svg/promocode.svg';
import { CancelledByUserId } from '../../types/enums';

interface TableRowProps {
  ride: Ride;
}

export const getOrderTime = (ride: Ride) => {
  let localTime;
  if (ride?.local_datetime && moment(ride?.local_datetime).isValid()) {
    localTime = moment.utc(ride?.local_datetime).format('DD.MM.YY на HH:mm');
  } else if (ride?.scheduled_time && moment(ride?.scheduled_time * 1000).isValid()) {
    localTime = formatDateValue(ride.scheduled_time, {
      format: 'DD.MM.YY на HH:mm',
      offset: ride?.city_timezone_offset,
    });
  } else {
    if (ride?.ctime) {
      formatDateValue(ride?.ctime, { format: 'DD.MM.YY / HH:mm', offset: ride?.city_timezone_offset });
    }
  }
  if (
    ['cancelled', 'error', 'failed', 'complete'].includes(ride.status_id as string) ||
    ride?.local_datetime === ride?.msk_datetime
  ) {
    return localTime;
  } else {
    return (
      <>
        {' '}
        {localTime}
        {!!ride?.msk_datetime && <div>{`(МСК: ${moment.utc(ride?.msk_datetime).format('DD.MM.YY / HH:mm')})`}</div>}
      </>
    );
  }
};
export const getRideTime = (ride: Ride): string | number => {
  if (!ride?.finished_time || !ride?.start_transporting_time) return '';
  const start =
    moment(ride.start_transporting_time * 1000)
      .seconds(0)
      .milliseconds(0)
      .unix() * 1000;
  const finish =
    moment(ride.finished_time * 1000)
      .add(1, 'minute')
      .seconds(0)
      .milliseconds(0)
      .unix() * 1000;
  return (finish - start) / 1000 / 60;
};
export const TableRow = ({ ride }: TableRowProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const { t } = useTranslation(['rides', 'order']);
  const { usersStore, ordersStore, rolesCostCentersStore } = useStores();

  const [classSuffix, status] = getStatusClassAndTitle(ride.status_id as string);

  useEffect(() => {
    (async () => {
      if (selected) {
        await ordersStore.fetchRecord(Number(ride.id), true);
        if (ordersStore?.record) {
          await rolesCostCentersStore.fetchFields({
            phone: ordersStore?.record.phone,
            promo_id: ordersStore?.record.promo_id,
            customer_id: ordersStore?.record.customer_id,
            employee_id: ordersStore?.record.employee_id,
          });
        }
      }
    })();
  }, [selected]);

  const renderDestination = (dest: typeof ride.destination, index: number): JSX.Element => (
    <div className="flex items-start space-x-1.5" key={index}>
      <svg className="w-5 h-5 flex-shrink-0" viewBox="0 0 20 20" fill="none" aria-hidden="true">
        <path
          d="M4.167 4.648s1.853-.824 3.5-.824 3.122 1.236 4.666 1.236c.644 0 1.36-.18 1.975-.388.713-.242 1.525.262 1.525 1.014v5.838a.93.93 0 0 1-.544.862c-.69.305-1.916.772-2.956.772-1.544 0-3.225-1.236-4.666-1.236-1.441 0-3.5.824-3.5.824V4.648Z"
          fill="#FDCD03"
        ></path>
        <rect x="4.167" y="3" width="1.167" height="14" rx=".583" fill="#000"></rect>
      </svg>
      <span className="overflow-hidden overflow-ellipsis ellipsis" style={{ width: '100%' }}>
        {dest?.address || ''}
      </span>
    </div>
  );

  const isLate =
    usersStore.isOperator &&
    (ride?.cancelled_by_user_id || 0) === CancelledByUserId.DriverNotFound &&
    moment()
      .utc()
      .diff(moment.utc((ride?.start_search_time || ride?.scheduled_time || ride?.ctime || 0) * 1000), 'minutes') > 5;
  return (
    <li className="data-item" onClick={() => setSelected(!selected)}>
      <div className={`data-row ${isLate ? 'data-highlight' : ''}`} role="button">
        <div className="w-[10.5rem]">
          <div className="space-y-1">
            <div>№{ride?.id}</div>
            <div className="text-gray">{getOrderTime(ride)}</div>
            <div className="mt-1"></div>
            {!usersStore?.isOperator && (
              <span className={`status ride-${classSuffix}`}>{t(`status.${ride.status_id}`, { ns: 'order' })}</span>
            )}
          </div>
        </div>
        {usersStore?.isOperator && (
          <div className="w-[9.25rem]">
            <div className="space-y-1 text-center">
              <span className={`status ride-${classSuffix}`}>{t(`status.${ride.status_id}`, { ns: 'order' })}</span>
              <p>
                {ride?.route?.is_toll_road ? <CurrencySign /> : ''}
                {ride?.promo_id || ride?.is_promo ? <PromoIcon className="inline-block mr-1" /> : ''}
                {ride?.class?.title}
              </p>
            </div>
          </div>
        )}
        <div className={`w-[${usersStore?.isOperator ? 3 : 1}5.5rem]`}>
          <div className="space-y-1">
            <div className="flex items-start space-x-1.5">
              <svg className="w-5 h-5 flex-shrink-0" viewBox="0 0 20 20" fill="none" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 16.416a6.417 6.417 0 1 0 0-12.833 6.417 6.417 0 0 0 0 12.833ZM9.18 7.408l2.89 1.734a1 1 0 0 1 0 1.715l-2.89 1.734a1 1 0 0 1-1.514-.857V8.266a1 1 0 0 1 1.514-.858Z"
                  fill="#FDCD03"
                ></path>
                <path
                  d="M12.07 9.142 9.182 7.408a1 1 0 0 0-1.514.858v3.467a1 1 0 0 0 1.514.858l2.89-1.734a1 1 0 0 0 0-1.715Z"
                  fill="#000"
                ></path>
              </svg>
              <span className="overflow-hidden overflow-ellipsis ellipsis" style={{ width: '100%' }}>
                {formatAddress(ride?.source, t('trow.entrance'))}
              </span>
            </div>
            {ride?.interim_destinations?.map(renderDestination)}
            {renderDestination(ride?.destination, 100)}
          </div>
        </div>
        <div className={`w-[${usersStore?.isOperator ? 17 : 9}.5rem]`}>
          <div className="space-y-1">
            {!usersStore.isOperator && <div>{t('trow.employee')}</div>}
            <div>
              {ride?.employee?.is_guest
                ? `${ride?.employee_name || ''} ${t('trow.guest')}`
                : ride?.employee?.name || ''}
            </div>
            <div className="text-gray">
              <CopiedText
                text={formatPhone(ride?.phone || ride?.employee?.phone || '')}
                textToCopy={ride?.phone || ride?.employee?.phone || ''}
              />
            </div>
          </div>
        </div>
        {usersStore?.isOperator && (
          <div className={`w-[9.5rem]`}>
            <div className="space-y-1">{ride?.customer?.name || usersStore?.me?.detail?.customer?.name || ''}</div>
          </div>
        )}
        {!usersStore?.isOperator && (
          <div className={`w-[12.5rem]`}>
            <div className="space-y-1">
              {ride?.performer ? (
                <>
                  <div className="overflow-hidden overflow-ellipsis">
                    {ride?.performer?.car
                      ?.split(' ')
                      .slice(0, ride?.performer?.car?.split(' ')?.length - 1)
                      .join(' ')}{' '}
                    / {ride?.performer?.car?.split(' ').pop()}
                  </div>
                  <div>{ride?.performer?.fullname || ''}</div>
                  {usersStore?.isOperator && ride?.performer?.phone ? (
                    <CopiedText
                      text={formatPerformerPhone(ride?.performer?.phone)}
                      textToCopy={ride?.performer?.phone}
                    />
                  ) : null}
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        )}
        {usersStore?.isOperator && (
          <div className={`w-[12.5rem]`}>
            {ride?.performer ? (
              <>
                <div className="overflow-hidden overflow-ellipsis ellipsis" style={{ width: '120px' }}>
                  {ride?.performer?.fullname || ''}
                </div>
                {usersStore?.isOperator && ride?.performer?.phone ? (
                  <CopiedText text={formatPerformerPhone(ride?.performer?.phone)} textToCopy={ride?.performer?.phone} />
                ) : null}
              </>
            ) : (
              '-'
            )}
          </div>
        )}
        {usersStore?.isOperator && (
          <div className={`w-[12.5rem]`}>
            {ride?.performer ? (
              <div className="overflow-hidden overflow-ellipsis">
                {ride?.performer?.car
                  ?.split(' ')
                  .slice(0, ride?.performer?.car?.split(' ')?.length - 1)
                  .join(' ')}{' '}
                / {ride?.performer?.car?.split(' ').pop()}
              </div>
            ) : (
              '-'
            )}
          </div>
        )}
        {!usersStore?.isOperator && (
          <div className="w-[6.25rem]">
            {ride?.route?.is_toll_road ? <CurrencySign /> : ''}
            {ride?.class?.title || ''}
          </div>
        )}
        {!usersStore?.isOperator && (
          <div className="w-[9.25rem]">
            {ride?.cost_customer_with_vat ? formatPrice(+ride.cost_customer_with_vat, '0,0.[00]', 2) : '-'}
          </div>
        )}
        {!usersStore?.isOperator && <div className="w-[9.5rem]">{ride?.comment || '-'}</div>}
        {!usersStore?.isOperator && (
          <div className="data-cell">
            <div className="space-y-1">
              {ride?.paid_waiting_time_s && ride.transporting_time_s ? (
                <>
                  <div>
                    {t('trow.paid_waiting')}:{' '}
                    <span className="text-gray">
                      {Math.ceil(ride.paid_waiting_time_s / 60)} {t('trow.minutes_short')}.
                    </span>
                  </div>
                  <div>
                    {t('trow.ride')}:{' '}
                    <span className="text-gray">
                      {getRideTime(ride)} {t('trow.minutes_short')}.
                    </span>
                  </div>
                </>
              ) : null}
              {ride?.arrival_time_s && ride.status_id === 'driving' ? (
                <div>
                  {t('trow.arrival_time')}:{' '}
                  <span className="text-gray">
                    {Math.ceil(ride.arrival_time_s / 60)} {t('trow.minutes_short')}.
                  </span>
                </div>
              ) : null}
              {ride.transporting_time_s ? (
                <div>
                  {t('trow.full_time')}:{' '}
                  <span className="text-gray">
                    {Math.ceil(ride.transporting_time_s / 60)} {t('trow.minutes_short')}.
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {ordersStore?.record?.id === ride?.id ? (
        <Details selected={selected} ride={ordersStore?.record} costCenters={rolesCostCentersStore.cost_centers} />
      ) : null}
    </li>
  );
};

export default TableRow;
